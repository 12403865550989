import {Ref} from "vue";
import {IShapeBlaster, IShapeBlasterShot} from "@/source/interface/IShape";
import * as resource from "@/resource/palitra";

export class ShapeBlaster {
    x: number
    y: number
    isLoadedShield: boolean
    isLoadedBlaster: boolean
    isDead: boolean
    isShield: boolean
    isShot: boolean
    imageBlaster: HTMLImageElement
    imageShield: HTMLImageElement
    canvas: HTMLCanvasElement
    speed: number
    scale: number

    constructor(imageBlasterSrc: string, imageShieldSrc: string, x: number, y: number, speed: number, canvas: HTMLCanvasElement, scale: number) {
        this.x = x;
        this.y = y;
        this.isLoadedShield = false;
        this.isLoadedBlaster = false;
        this.isDead = false;
        this.isShield = false;
        this.isShot = false;
        this.imageBlaster = new Image();
        this.imageShield = new Image();
        // eslint-disable-next-line
        let obj = this;
        this.imageBlaster.addEventListener("load", function () { obj.isLoadedBlaster = true; });
        this.imageBlaster.addEventListener("error", function () { alert('That image was not found.'); });
        this.imageBlaster.src = imageBlasterSrc;
        this.imageShield.addEventListener("load", function () { obj.isLoadedShield = true; });
        this.imageShield.addEventListener("error", function () { alert('That image was not found.'); });
        this.imageShield.src = imageShieldSrc;
        this.canvas = canvas;
        this.speed = speed;
        this.scale = scale;
    }

    draw(ctx: Ref): void {
        ctx.value.drawImage(
            this.imageBlaster,
            0,
            0,
            this.imageBlaster.width,
            this.imageBlaster.height,
            this.x,
            this.y,
            this.imageBlaster.width * this.scale,
            this.imageBlaster.height * this.scale
        );
        if (this.isShield) {
            ctx.value.drawImage(
                this.imageShield,
                0,
                0,
                this.imageShield.width,
                this.imageShield.height,
                (this.x + (this.imageBlaster.width * this.scale) + 10 * this.scale),
                (this.y + ((this.imageBlaster.height * this.scale) / 2)) - ((this.imageShield.height * this.scale) / 2) - 35 * this.scale,
                this.imageShield.width * this.scale,
                this.imageShield.height * this.scale
            );
        }
    }

    move(vector: string, step: number): void {
        // Если перемещаемся по оси y
        if(vector == "y") {
            this.y += step;

            if(this.y + (this.imageBlaster.height * this.scale) > this.canvas.height) {
                this.y -= step;
            }

            if (this.y < 0) {
                this.y = 0;
            }
        }
    }
}


export class ShapeBlasterShot {
    x: number
    y: number
    isLoadedShot: boolean
    isShoted: boolean
    isDead: boolean
    isHit: boolean
    imageShot: HTMLImageElement
    canvas: HTMLCanvasElement
    speed: number
    scale: number
    indexRectMove: number
    vectorY: string

    constructor(imageShotSrc: string, x: number, y: number, speed: number, canvas: HTMLCanvasElement, scale: number) {
        this.x = x;
        this.y = y;
        this.isLoadedShot = false;
        this.isShoted = false;
        this.isDead = false;
        this.isHit = false;
        this.imageShot = new Image();
        // eslint-disable-next-line
        let obj = this;
        this.imageShot.addEventListener("load", function () { obj.isLoadedShot = true; });
        this.imageShot.addEventListener("error", function () { alert('That image was not found.'); });
        this.imageShot.src = imageShotSrc;
        this.canvas = canvas;
        this.speed = speed;
        this.scale = scale;
        this.indexRectMove = 0;
        this.vectorY = "down";
    }

    draw(ctx: Ref): void {
        if (this.isShoted) {
            ctx.value.drawImage(
                this.imageShot,
                0,
                0,
                this.imageShot.width,
                this.imageShot.height,
                this.x,
                this.y,
                this.imageShot.width * this.scale,
                this.imageShot.height * this.scale
            );
        }
    }

    calcIndexRectMove(): void {
        this.vectorY = this.y > this.canvas.height / 2 ? 'up' : 'down';
        let heightRect = 0;
        if (this.vectorY === 'down') {
            let deductibleHeight = this.y + this.canvas.height / 12;
            if (this.x < this.canvas.width / 2) {
                deductibleHeight = this.y + this.canvas.height / 7;
            }
            if (this.x < this.canvas.width / 2.5) {
                deductibleHeight = this.y + this.canvas.height / 5;
            }
            if (this.x < this.canvas.width / 3) {
                deductibleHeight = this.y + this.canvas.height / 2;
            }
            heightRect = this.canvas.height - deductibleHeight;
        }
        if (this.vectorY === 'up') {
            let deductibleHeight = this.canvas.height / 12;
            if (this.x < this.canvas.width / 2) {
                deductibleHeight = this.canvas.height / 7;
            }
            if (this.x < this.canvas.width / 2.5) {
                deductibleHeight = this.canvas.height / 5;
            }
            if (this.x < this.canvas.width / 3) {
                deductibleHeight = this.canvas.height / 2;
            }
            heightRect = this.y - deductibleHeight;
        }

        this.indexRectMove = heightRect / this.x;
    }

    /**
     * Проверяем отразил ли щит выстрел.
     * @param shapeBlaster
     * @return boolean
     */
    checkReflectShot(shapeBlaster: IShapeBlaster): boolean {
        const bl = shapeBlaster;
        const blShieldX = bl.x + (bl.imageBlaster.width * bl.scale) + 10 * bl.scale;
        const blShieldY = (bl.y + ((bl.imageBlaster.height * bl.scale) / 2)) - ((bl.imageShield.height * bl.scale) / 2) - 35 * bl.scale;
        const shieldRight = blShieldX + bl.imageShield.width * bl.scale;
        const shieldTop = blShieldY;
        const shieldBotton = blShieldY + bl.imageShield.height * bl.scale;
        return this.isHit && this.isShoted && this.x <= shieldRight && this.y >= shieldTop && this.y <= shieldBotton;
    }

    move(): void {
        // Если перемещаемся по оси y
        if (this.isHit && this.indexRectMove === 0) {
            this.calcIndexRectMove();
        }
        if (!this.isHit) {
            this.x += this.speed; //Changing position
            //Rolling back the changes if the car left the screen
            if (this.x > this.canvas.width) {
                this.isDead = true;
            }

            if (this.x < 0 - this.imageShot.width - 5) {
                this.isDead = true;
            }
        } else {
            this.x -= this.speed / 2;
            switch (this.vectorY) {
                case 'down':
                    this.y = this.y + this.speed / 2 * this.indexRectMove;
                    break;
                case 'up':
                    this.y = this.y - this.speed / 2 * this.indexRectMove;
                    break;
            }

            if (this.x + this.imageShot.width * this.scale + 5 < 0) {
                this.isDead = true;
            }
        }
    }
}


export class GetShapeBlaster {
    resourceBlaster: any
    resourceShield: any
    canvas: Ref<HTMLCanvasElement>
    speed: number
    scale: number
    getShape: Generator<IShapeBlaster>
    shapeBlaster: IShapeBlaster


    constructor(resourceBlaster: any, resourceShield: any, canvas: Ref<HTMLCanvasElement>, speed: number, scale: number) {
        this.resourceBlaster = resourceBlaster;
        this.resourceShield = resourceShield;
        this.canvas = canvas;
        this.speed = speed;
        this.scale = scale;
        this.getShape = this.generatorShapeBlaster();
        this.shapeBlaster = this.getShape.next().value;
    }

    * generatorShapeBlaster(): Generator<IShapeBlaster> {
        while (true) {
            yield new ShapeBlaster(this.resourceBlaster, this.resourceShield,
                10,
                50,
                this.speed,
                this.canvas.value,
                this.scale);
        }
    }

    getShapeBlaster(): IShapeBlaster {
        if (this.shapeBlaster.isDead) {
            this.shapeBlaster = this.getShape.next().value;
        }
        return this.shapeBlaster
    }
}


export class GetShapeBlasterShot {
    resourceShot: any
    canvas: Ref<HTMLCanvasElement>
    speed: number
    scale: number
    getShape: Generator<IShapeBlasterShot>
    shapeShot: IShapeBlasterShot | null


    constructor(resourceShot: any, canvas: Ref<HTMLCanvasElement>, speed: number, scale: number) {
        this.resourceShot = resourceShot;
        this.canvas = canvas;
        this.speed = speed;
        this.scale = scale;
        this.getShape = this.generatorShapeBlasterShot();
        this.shapeShot = this.getShape.next().value;
    }

    * generatorShapeBlasterShot(): Generator<IShapeBlasterShot> {
        while (true) {
            yield new ShapeBlasterShot(this.resourceShot,
                0,
                0,
                this.speed,
                this.canvas.value,
                this.scale);
        }
    }

    getShapeBlasterShot(): IShapeBlasterShot | null {
        if (this.shapeShot?.isDead || !this.shapeShot) {
            this.shapeShot = null;
            this.shapeShot = this.getShape.next().value;
        }
        return this.shapeShot
    }
}


export function useGetShapeBlaster(canvas: Ref<HTMLCanvasElement>, speed: number, scale: number): GetShapeBlaster {
    return new GetShapeBlaster(resource.blaster, resource.energyShield, canvas, speed, scale)
}

export function useGetShapeBlasterShot(canvas: Ref<HTMLCanvasElement>, speed: number, scale: number): GetShapeBlasterShot {
    return new GetShapeBlasterShot(resource.blasterShot, canvas, speed, scale)
}

