import { Ref } from "vue";
import {IShape, IShapeTrackP, IShapeTrackS} from "@/source/interface/IShape";
import * as resource from "@/resource/volume";
import { arrayRandElement } from "@/source/functions/randomElement";
import { ResourceLenError } from "@/source/class/Errors";
import {TObjectCoor} from "@/source/type/types";
import { TTrack } from "@/source/type/types";


export class ShapeTrackS {
    x: number
    y: number
    loaded: boolean
    isDead: boolean
    image: HTMLImageElement
    canvas: HTMLCanvasElement
    speed: number
    scale: number
    track: TTrack
    step: number

    constructor(image: string, track: Ref<TTrack>, speed: number, canvas: HTMLCanvasElement, scale: number) {
        this.track = [[100, 100], [canvas.width - 100, 100], [canvas.width - 100, canvas.height - 100], [ 100, canvas.height - 100]];
        this.x = this.track[0][0];
        this.y = this.track[0][1];
        this.loaded = false;
        this.isDead = false;
        this.image = new Image();
        // eslint-disable-next-line
        let obj = this;
        this.image.addEventListener("load", function () { 
            obj.loaded = true;
            obj.track = obj.buildTrack(obj.track);
            obj.x = obj.track[0][0];
            obj.y = obj.track[0][1];
        });
        this.image.addEventListener("error", function () { console.log('ShapeTrack image was not found.'); });
        this.image.src = image;
        this.canvas = canvas;
        this.speed = speed;
        this.scale = scale;
        this.step = 0;
    }

    buildTrack(track: TTrack): TTrack {
        const temp = track;
        temp[1][0] = temp[1][0] - this.image.width * this.scale;
        temp[2][1] = temp[2][1] - this.image.height * this.scale;
        temp[3][1] = temp[3][1] - this.image.height * this.scale;
        return temp
    }

    moveTrack(): void {
        if (!this.isDead) {
            switch (this.step) {
                case 0:
                    if (this.x < this.track[1][0]) {
                        this.move('x', 1);
                    } else {
                        this.step ++;
                    }
                    break;
                case 1:
                    if (this.y < this.track[2][1]) {
                        this.move('y', 1);
                    } else {
                        this.step ++;
                    }
                    break;
                case 2:
                    if (this.x > this.track[3][0]) {
                        this.move('x', -1);
                    } else {
                        this.step ++;
                    }
                    break;
                case 3:
                    if (this.y > this.track[0][1]) {
                        this.move('y', -1);
                    } else {
                        this.isDead = true;
                    }
                    break;
            }
        }
    }

    draw(ctx: Ref): void {
        ctx.value.drawImage(
            this.image,
            0,
            0,
            this.image.width,
            this.image.height,
            this.x,
            this.y,
            this.image.width * this.scale,
            this.image.height * this.scale
        );
    }

    move(vector: string, step: number) {
        // Если перемещаемся по оси х
        if(vector == "x") {
            this.x += step; //Changing position
            //Rolling back the changes if the car left the screen
            if(this.x + (this.image.width * this.scale / 2 ) > this.canvas.width) {
                this.x -= step;
            }

            if(this.x < 0) {
                this.x = 0;
            }
        } else {
            this.y += step;

            if(this.y + (this.image.height * this.scale / 2) > this.canvas.height) {
                this.y -= step;
            }

            if(this.y < 0) {
                this.y = 0;
            }
        }

    }
}

export class ShapeTrackP {
    x: number  // Позиция на карте изображения 1 х
    y: number  // Позиция на карте изображения 1 y
    image: HTMLImageElement // Изображение 1
    pos: Array<number>  // позиция x,y изображения на спрайт карте
    size: Array<number>  // Размеры одного изображения на спрайт карте x,y
    lenFrames: number // колличество кадров на спрайт карте
    speedAnimate: number  // Скорость перемещения по спрайт карте
    loaded: boolean  // Статус загрузки изображений
    isDead: boolean  // Статус сопастовления
    canvas: HTMLCanvasElement  // Канвас
    speed: number  // Скорость
    scale: number  // Масштаб
    isAnimated: boolean
    timer: number

    constructor(
        image: string,
        posCanvas: Array<number>,
        lenFrames: number,
        speedAnimate: number,
        canvas: HTMLCanvasElement,
        speed: number,
        scale: number
    ) {
        [this.x, this.y] = posCanvas;
        this.loaded = false;
        this.isDead = false;
        this.lenFrames = lenFrames;
        this.pos = [0, 0];
        this.size = [0, 0];
        this.isAnimated = false;
        this.speedAnimate = speedAnimate;
        this.canvas = canvas;
        this.speed = speed;
        this.scale = scale;
        this.image = new Image();
        // eslint-disable-next-line
        let obj = this;
        this.image.addEventListener("load", function () {
            obj.loaded = true;
            obj.size = [obj.image.width / obj.lenFrames, obj.image.height];
            obj.x = obj.x - obj.image.width * obj.scale;
            obj.y = obj.y - obj.image.height * obj.scale;
        });
        this.image.addEventListener("error", function () { console.log('That image1 was not found.'); });
        this.image.src = image;
        this.timer = this.startAnimate();
    }

    startAnimate() {
        this.isAnimated = true;
        let curentFrame = 0;
        return setInterval(() => {
            this.pos[0] = this.size[0] * curentFrame;
            this.pos[1] = 0;

            if (curentFrame < this.lenFrames - 1) {
                curentFrame += 1;
            } else {
                curentFrame = 0;
            }
        }, this.speedAnimate);
    }

    draw(ctx: Ref) {
        ctx.value.drawImage(
            this.image,
            this.pos[0],
            this.pos[1],
            this.size[0],
            this.size[1],
            this.x,
            this.y,
            this.size[0] * this.scale,
            this.size[1] * this.scale
        );
    }

    Move(vector: string, step: number) {
        // Если перемещаемся по оси х
        if(vector == "x") {
            this.x += step; //Changing position
            //Rolling back the changes if the car left the screen
            if(this.x + (this.image.width * this.scale / 2) > this.canvas.width) {
                this.x -= step;
            }

            if(this.x < 0) {
                this.x = 0;
            }
        } else {
            this.y += step;

            if(this.y + (this.image.height * this.scale / 2) > this.canvas.height) {
                this.y -= step;
            }

            if(this.y < 0) {
                this.y = 0;
            }
        }
    }
}

//
export class GetShapeTrackObj {
    resourceShape: Array<any>
    resourcePlayer: Array<any>
    canvas: Ref<HTMLCanvasElement>
    speed: number
    speedAnimate: number
    scale: number
    lenResourcesStep: number
    currentPlayerStep: number
    currentShapeStep: number
    shapeTrack: Ref<TTrack>
    getShapeG: Generator<IShapeTrackS>
    shape: IShapeTrackS
    getPlayerG: Generator<IShapeTrackP>
    player: IShapeTrackP

    constructor(resourceShape: Array<any>,
                resourcePlayer: Array<any>,
                canvas: Ref<HTMLCanvasElement>,
                speed: number,
                speedAnimate: number,
                scale: number,
                track: Ref<TTrack>) {
        this.resourceShape = resourceShape;
        this.resourcePlayer = resourcePlayer;
        this.canvas = canvas;
        this.speed = speed;
        this.speedAnimate = speedAnimate;
        this.scale = scale;
        if (resourceShape.length !== resourcePlayer.length) {
            throw new ResourceLenError('Resource red and blue lengths are not equal')
        } else {
            this.lenResourcesStep = resourcePlayer.length;
        }
        this.currentPlayerStep = 0;
        this.currentShapeStep = 0;
        this.shapeTrack = track;
        this.getShapeG = this.generatorShape();
        this.shape = this.getShapeG.next().value;

        this.getPlayerG = this.generatorPlayer();
        this.player = this.getPlayerG.next().value;
    }

    * generatorPlayer() {
        while (true) {
            this.currentPlayerStep = 0;
            while (this.currentPlayerStep <= this.lenResourcesStep - 1) {
                yield new ShapeTrackP(
                    this.resourcePlayer[this.currentPlayerStep],
                    [this.canvas.value.width - 50, this.canvas.value.height - 50],
                    2,
                    this.speedAnimate,
                    this.canvas.value,
                    this.speed, this.scale); //Player's object
                this.currentPlayerStep += 1;
            }
        }
    }

    * generatorShape() {
        while (true) {
            this.currentShapeStep = 0;
            while (this.currentShapeStep <= this.lenResourcesStep - 1) {
                yield new ShapeTrackS(
                    this.resourceShape[this.currentShapeStep],
                    this.shapeTrack, this.speed, this.canvas.value, this.scale); //Shape's object
                this.currentShapeStep += 1;
            }
        }
    }

    getPlayer() {
        if (this.player.isDead) {
            this.player = this.getPlayerG.next().value;
        }
        return this.player;
    }

    getShape() {
        if (this.shape.isDead) {
            this.player.isDead = true;
            this.shape = this.getShapeG.next().value;
        }
        return this.shape;
    }

    checkHit(val: Ref<boolean>) {
        if ((this.player.x >= this.shape.x - 10 && this.player.x <= this.shape.x + 10) && (this.player.y >= this.shape.y - 10 && this.player.y <= this.shape.y + 10 )) {
            this.player.isDead = true;
            this.shape.isDead = true;
            return !val.value
        } else {
            return val.value
        }
    }
}


export function useGetShapeTrackObj(canvas: Ref<HTMLCanvasElement>, speed: number, speedAnimate: number, scale: number, track: Ref<TTrack>) {
    const resorcePlayer = [
        resource.shapeTrack1p,
        resource.shapeTrack2p,
        resource.shapeTrack3p,
        resource.shapeTrack4p,
    ];
    const resorceShape = [
        resource.shapeTrack1m,
        resource.shapeTrack2m,
        resource.shapeTrack3m,
        resource.shapeTrack4m,
    ];
    return new GetShapeTrackObj(resorceShape, resorcePlayer, canvas, speed, speedAnimate, scale, track)
}