
export class StarCount {
    x: number
    y: number
    isLoaded: boolean
    dead: boolean
    isPlayer: boolean
    image: HTMLImageElement
    canvas: HTMLCanvasElement
    speed: number
    scale: number

    constructor(image: string, x: number, y: number, speed: number, isPlayer: boolean, canvas: HTMLCanvasElement, scale: number) {
        this.x = x;
        this.y = y;
        this.isLoaded = false;
        this.dead = false;
        this.isPlayer = isPlayer;
        this.image = new Image();
        // eslint-disable-next-line
        let obj = this;
        this.image.addEventListener("load",  () => { obj.isLoaded = true;
            obj.x = ((this.image.width * this.scale) / 2) + 10
            obj.y = ((this.image.height * this.scale) / 2) + 10
        });
        this.image.addEventListener("error", () => { obj.isLoaded = false; });
        this.image.src = image;
        this.canvas = canvas;
        this.speed = speed
        this.scale = scale

    }

    SetPos(x: number, y: number) {
        this.x = this.x + ((this.image.width * this.scale) / 2) + x
        this.y = this.y + ((this.image.height * this.scale) / 2) + y
    }

    Move(vector: string, step: number) {
        // Если перемещаемся по оси х
        if(vector == "x") {
            this.x += step; //Changing position
            //Rolling back the changes if the car left the screen
            if(this.x + (this.image.width / 2) > this.canvas.width) {
                this.x -= step;
            }

            if(this.x - (this.image.width / 2) < 0) {
                this.x = this.image.width / 2 ;
            }
        } else {
            this.y += step;

            if(this.y + (this.image.height / 2) > this.canvas.height) {
                this.y -= step;
            }

            if(this.y - (this.image.height / 2) < 0) {
                this.y = this.image.height / 2;
            }
        }

    }
}

