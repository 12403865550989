import {IMoveBackgroundPoint, IShape, IStarCount} from "@/source/interface/IShape";
import {Ref} from "vue";

export class MoveBackground {
    x: number
    y: number
    isLoaded: boolean
    image: HTMLImageElement
    speed: number

    constructor(image: string, speed: number) {
        this.isLoaded = false;
        this.image = new Image();
        this.speed = speed
        // eslint-disable-next-line
        let obj = this;
        this.image.addEventListener("load", function () { obj.isLoaded = true; });
        this.image.src = image;
        this.x = 0;
        this.y = 0;
    }

    move() {
        this.x -= this.speed; //The image will move down with every frame

        if(this.image.width + this.x < window.innerWidth) {
            this.x = window.innerWidth - this.image.width //New position depends on the second Road object
        }
    }
}


export class MoveBackgroundPoint {
    x: number
    y: number
    isLoaded: boolean
    image: HTMLImageElement
    canvas: HTMLCanvasElement
    scale: number

    constructor(image: string, canvas: HTMLCanvasElement, scale: number) {
        this.x = 0;
        this.y = 0;
        this.canvas = canvas;
        this.scale = scale;
        this.isLoaded = false;
        this.image = new Image();
        // eslint-disable-next-line
        let self = this;
        this.image.addEventListener("load",  () => { self.isLoaded = true;
        });
        this.image.addEventListener("error", () => { self.isLoaded = false; });
        this.image.src = image;
    }

    draw(ctx: Ref): void {
        ctx.value.drawImage(
            this.image,
            0,
            0,
            this.image.width,
            this.image.height,
            this.canvas.width / 2 - this.image.width * this.scale / 2,
            this.canvas.height / 2 - this.image.height * this.scale / 2,
            this.image.width * this.scale,
            this.image.height * this.scale
        );
    }
}