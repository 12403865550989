
import {computed, defineComponent, onMounted, ref, watch, onUnmounted, Ref, nextTick} from 'vue';
import {Shape} from "@/source/class/shape";
import {MoveBackground, MoveBackgroundPoint} from "@/source/class/moveBackground";
import {handlerInput, handlerInputBlaster, handlerInputTime, setKey} from "@/source/functions/input";
import {
    IMoveBackground,
    IShape,
    IStarCount,
    IMovePalitraBackground,
    IShapeAIM,
    IMoveBackgroundPoint,
    IShapeBlaster,
    IShapeBlasterShot,
    IMovePalitraBackgroundPointArea
} from "@/source/interface/IShape";
import * as resource from "@/resource/volume";
// import { fullScreenWithoutStatus} from "@/source/functions/fullScreen";
import { StarCount } from "@/source/class/starCount";
import {useRoute, useRouter} from "vue-router";
import {useGetMovePalitraBackground, useGetMovePalitraBackgroundForBlaster} from "@/source/class/movePalitraBackground";
import {useGetShapeAIM} from "@/source/class/shapeAim";
import {useGetShapeBlaster, useGetShapeBlasterShot} from "@/source/class/shapeBlaster";


export default defineComponent({
	name: 'GamePalitra',
    inheritAttrs: false,
	props: {
		msg: String,
	},
	setup() {

		onMounted(() => {
            nextTick(() => {
                resize(); // При загрузке страницы задаётся размер холста

                window.addEventListener("resize", resize); //При изменении размеров окна будут меняться размеры холста
                document.addEventListener('keydown', keyHandlerDown);
                document.addEventListener('keyup', keyHandlerUp);
                window.addEventListener('blur', keyHandlerBlur);

                document.addEventListener("fullscreenchange", fullScreenHandler);
                document.addEventListener("mozfullscreenchange", fullScreenHandler);
                document.addEventListener("webkitfullscreenchange", fullScreenHandler);
                document.addEventListener("msfullscreenchange", fullScreenHandler);

                moveBackground.value = new MoveBackground(resource.moveBackground, 2); // Обьект перерыва
                moveBackgroundPoint.value = new MoveBackgroundPoint(resource.moveBackgroundPoint, canvas.value, 0.3) // Обьект перерыва

                starCount.value = new StarCount(resource.starCountCut, 0, 0, speed.value, false, canvas.value, 0.3); //Player's object

                getPalitraMove.value = useGetMovePalitraBackground(canvas, 50, 1);
                palitraMoveBackground.value = getPalitraMove.value.getResourse();

                getPalitraMoveForBlaster.value = useGetMovePalitraBackgroundForBlaster(canvas, 1);
                palitraMoveBackgroundForBlaster.value = getPalitraMoveForBlaster.value.getResources();

                getAIM.value = useGetShapeAIM(canvas, 150, speed.value, 0.3);
                shapeAIM.value = getAIM.value.getShapeAIM();

                getBlaster.value = useGetShapeBlaster(canvas, speed.value, 0.5);
                shapeBlaster.value = getBlaster.value.getShapeBlaster();

                getShot.value = useGetShapeBlasterShot(canvas, speed.value * 2, 0.5);
                shapeShot.value = getShot.value.getShapeBlasterShot();

                // if (!document.fullscreenElement?.nodeName) {
                //     fullScreenWithoutStatus(html);
                // }
                start();
            });


		});

		onUnmounted(() => {
            document.removeEventListener('keydown', keyHandlerDown);
            document.removeEventListener('keyup', keyHandlerUp);
            window.removeEventListener('blur', keyHandlerBlur);
            document.removeEventListener("fullscreenchange", fullScreenHandler);
            document.removeEventListener("mozfullscreenchange", fullScreenHandler);
            document.removeEventListener("webkitfullscreenchange", fullScreenHandler);
            document.removeEventListener("msfullscreenchange", fullScreenHandler);
			stop();
		});

        const keyHandlerDown = (e: any) => {
            setKey(e, true,  pressedKeys.value);
        }

        const keyHandlerUp = (e: any) => {
            setKey(e, false, pressedKeys.value);
        }

        const keyHandlerBlur = () => {
            pressedKeys.value = {};
        }

        const fullScreenHandler = () => {
            isFullScreen.value = document.fullscreenElement?.nodeName === 'HTML';
        }

		const UPDATE_TIME = 1000 / 120;
		const canvas = ref(); // ссылка на канвас
		const ctx = computed(() => canvas.value.getContext("2d") ); //Получение контекста — через него можно работать с холстом
		const getPalitraMove = ref();
		const palitraMoveBackground: Ref<IMovePalitraBackground | undefined> = ref();
		const getAIM = ref();
		const shapeAIM: Ref<IShapeAIM | undefined> = ref();

		/* region BLASTER */
		const getPalitraMoveForBlaster = ref();
		const palitraMoveBackgroundForBlaster: Ref<IMovePalitraBackgroundPointArea | undefined> = ref();
        const checkHitShot = ref(false);
		const getBlaster = ref(); // Выстрел
		const shapeBlaster: Ref<IShapeBlaster | undefined> = ref();
		const getShot = ref(); // Выстрел
		const shapeShot: Ref<IShapeBlasterShot | undefined> = ref();
		const starCount: Ref<IStarCount | undefined> = ref(); // Фигура
		const starHit: Ref<IShape | boolean> = ref(false); // Фигура
		const moveBackground: Ref<IMoveBackground | undefined> = ref();
		const moveBackgroundPoint: Ref<IMoveBackgroundPoint | undefined> = ref();
		const speed = ref(2);  // Скорость игрока
		const update_timer = ref(); // Счётчик для обновления канваса
		const global_timer = ref(); // Счётчик времени игры
		const time_stop = ref(480); // Время игры в секундах - 8 минут
		const game_time = ref(0);  // Количество времени которое прошло с начала игры
		const pressedKeys = ref({}); // Объект с нажатыми кнопками
		const point = ref(0);
		const breakPoint = [90, 250, 400];
		const breakPointTime = 5;
		const isBreakpoint = ref({status: false, startTime: 0, isDraw: false, countBreakpoint: 0 });
		const isHit = ref(false);
		const isFullScreen = ref(true);
		const html = document.documentElement;
        const route = useRoute();
        const router = useRouter();

		function * generatorStarHint(): IterableIterator<IShape> {
			while (true) {
				yield new Shape(resource.star, canvas.value.width / 2, canvas.value.height / 2, speed.value, false, canvas.value, 0.1); //Player's object
			}
		}

		const getStarHint = generatorStarHint();

		/**
		 * Функция проверяет не истекло ли время игры. Если истекло, то завершает её, если нет - то увеличивает основной счечик.
		 */
		const globalTime = () => {
			if (breakPoint.indexOf(game_time.value) !== -1) {
				isBreakpoint.value.status = true;
				isBreakpoint.value.startTime = game_time.value;
				isBreakpoint.value.countBreakpoint += 1;
			}

			if (isBreakpoint.value.status && game_time.value > isBreakpoint.value.startTime + breakPointTime ) {
				isBreakpoint.value.status = false;
				isBreakpoint.value.startTime = 0;
				isBreakpoint.value.isDraw = false;
				if (moveBackground.value !== undefined) {
					moveBackground.value.x = 0;
				}
			}

			if (game_time.value === time_stop.value) {
				stop();
				goBack();
			} else {
				game_time.value += 1;
			}
		};

		const goBack = (isBreak = false) => {
            window.removeEventListener("resize", resize);
			if (isBreak) {
				router.push({ name: 'GamesIntroWithID', params: { game_id: 'palitra'}, query: {point: 0, isEnd: 'break'}});
			} else {
				router.push({ name: 'GamesIntroWithID', params: { game_id: 'palitra'}, query: {point: point.value, isEnd: 'end'} });
			}
		};

		watch(() => isFullScreen.value, () => {
			if (!isFullScreen.value) {
				stop();
			} else {
				pressedKeys.value = {};
				// fullScreenWithoutStatus(html);
				start();
			}
		});


		/**
		 * Функция запуска игры
		 */
		const start = () => {
			isFullScreen.value = true;
			if (!global_timer.value) {
				global_timer.value = setInterval(globalTime, 1000); // Запуск счётчика времени игры, 1 раз за секунду.
			}
			if (!update_timer.value) {
				update_timer.value = setInterval(updateCanvas, UPDATE_TIME); //Состояние игры будет обновляться 60 раз в секунду — при такой частоте обновление происходящего будет казаться очень плавным
			}
		};

		/**
		 * Функция остановки игры
		 */
		const stop = () => {
			clearInterval(global_timer.value); //Остановка глобального счетчика
			clearInterval(update_timer.value); //Остановка счетчика обновления канваса
			global_timer.value = undefined;
			update_timer.value = undefined;
			game_time.value = 0;
		};

		/**
		 * Функция отображения игрока
		 * @param obj
		 * @param scale
		 */
		const drawObj = (obj: IShape | IStarCount | IMoveBackgroundPoint, scale: number) => {
			ctx.value.drawImage(
				obj.image,
				0,
				0,
				obj.image.width,
				obj.image.height,
				obj.x - ((obj.image.width * scale) / 2),
				obj.y - ((obj.image.height * scale) / 2),
				obj.image.width * scale,
				obj.image.height * scale
			);
		};

		const drawPart1 = () => {
			handlerInput(pressedKeys.value, shapeAIM, speed, isFullScreen);
			if (shapeAIM.value !== undefined && palitraMoveBackground.value !== undefined) {
				isHit.value = shapeAIM.value?.checkHit(palitraMoveBackground.value, isHit);
			}
			palitraMoveBackground.value = getPalitraMove.value.getResourse();
			shapeAIM.value = getAIM.value.getShapeAIM();
			if (palitraMoveBackground.value !== undefined && palitraMoveBackground.value?.isLoadedEnd && palitraMoveBackground.value?.isLoadedStart) {
				palitraMoveBackground.value?.draw(ctx);
				palitraMoveBackground.value?.move();
			}
			if (shapeAIM.value !== undefined && shapeAIM.value.isLoaded) {
				shapeAIM.value?.draw(ctx);
			}
		}

        /**
         * Обнуляем checkHitShot в случае если класс уничтожен.
         */
        watch(() => shapeShot.value?.isDead, (newVal) => {
            if (newVal && checkHitShot.value) {
                checkHitShot.value = false;
            }
        })

		const drawPart2 = () => {
			handlerInputBlaster(pressedKeys.value, shapeBlaster, speed);

            // Проверяем есть ли фигуры
            if (shapeShot.value !== undefined && shapeBlaster.value !== undefined) {
                // Если выстрел вылетел за пределы экрана - убит
                if (shapeShot.value?.isDead) {
                    // Отключаем флаги у бластера о том что он выстрелил и о включении щита
                    shapeBlaster.value.isShot = false;
                    shapeBlaster.value.isShield = false;
                    if (palitraMoveBackgroundForBlaster.value !== undefined) {
                        // Запускаем движение фона
                        palitraMoveBackgroundForBlaster.value.isStop = false;
                    }
                }
                if (palitraMoveBackgroundForBlaster.value !== undefined) {
                    const shotX = shapeShot.value.x + shapeShot.value.imageShot.width * shapeShot.value.scale / 2;
                    const shotY = shapeShot.value.y + shapeShot.value.imageShot.height * shapeShot.value.scale / 2;
                    // Если фон существует высчитываем координаты выстрела и проверяем на попадание по мишени на фоне.
                    // Если выстрел в пределах мишени устанавливаем флаг попадания.
                    checkHitShot.value = palitraMoveBackgroundForBlaster.value?.checkHit(checkHitShot, shotX, shotY);
                    // если произошло попадание, то устанавливаем флаги попадания у выстрела и у бластера включаем щит.
                    if (checkHitShot.value && !shapeShot.value.isHit && !shapeBlaster.value.isShield) {
                        shapeShot.value.isHit = true;
                        shapeBlaster.value.isShield = true;
                    }
                    // Проверяем отразил ли щит выстрел.
                    if (shapeShot.value?.checkReflectShot(shapeBlaster.value)) {
                        // Включаем флаг успешного попадания для увеличения очков
                        isHit.value = true;
                        // Сбрасываем флаги у бластера выстрела и фона, для генерации новых.
                        shapeShot.value.isHit = false;
                        shapeBlaster.value.isShield = false;
                        shapeShot.value.isDead = true;
                        shapeBlaster.value.isDead = true;
                        checkHitShot.value = false;
                        palitraMoveBackgroundForBlaster.value.isDead = true;
                    }
                }
            }
            // Запрос ресурсов
            palitraMoveBackgroundForBlaster.value = getPalitraMoveForBlaster.value.getResources();
            shapeBlaster.value = getBlaster.value.getShapeBlaster();
            shapeShot.value = undefined;
            shapeShot.value = getShot.value.getShapeBlasterShot();
            // Отрисовываем и перемещаем фон
			if (palitraMoveBackgroundForBlaster.value !== undefined && palitraMoveBackgroundForBlaster.value?.isLoadedEnd && palitraMoveBackgroundForBlaster.value?.isLoadedStart) {
				palitraMoveBackgroundForBlaster.value?.draw(ctx);
				palitraMoveBackgroundForBlaster.value?.move();
			}
            // Отрисовываем бластер
			if (shapeBlaster.value !== undefined && shapeBlaster.value?.isLoadedBlaster && shapeBlaster.value?.isLoadedShield) {
				shapeBlaster.value?.draw(ctx);
			}
            // Отрисовываем выстрел.
            if (shapeShot.value !== undefined && shapeShot.value?.isLoadedShot && shapeBlaster.value?.isShot && shapeBlaster.value !== undefined) {
                // Устанавливаем координаты появления выстрела останавливаем фон
                if (!shapeShot.value.isShoted) {
                    shapeShot.value.x = shapeBlaster.value.x + shapeBlaster.value?.imageBlaster.width * shapeBlaster.value?.scale + 5;
                    shapeShot.value.y = shapeBlaster.value.y - 5 * shapeBlaster.value.scale;
                    shapeShot.value.isShoted = true;
                    if (palitraMoveBackgroundForBlaster.value !== undefined) {
                        palitraMoveBackgroundForBlaster.value.isStop = true;
                    }
                }
                // отрисовывем и перемещаем выстрел
                if (shapeShot.value.isShoted) {
                    shapeShot.value?.draw(ctx);
                    shapeShot.value?.move();
                }
            }
		}


		const handlerPart = () => {
			switch (isBreakpoint.value.countBreakpoint) {
				case 0:
					drawPart1();
					break;
				case 1:
					drawPart1();
					break;
				case 2:
					drawPart1();
					break;
				case 3:
					drawPart2();
					break;
			}
		}

        function str_pad_left(string: any, pad: any, length: any) {
            return (new Array(length + 1).join(pad) + string).slice(-length);
        }

        function checkShowTime() {
            if (handlerInputTime(pressedKeys.value) && !route.query.showTime) {
                router.replace({ name: 'GamesWithID', params: { game_id: 'palitra' }, query: {showTime: 'true'} });
            }
            if (handlerInputTime(pressedKeys.value) && route.query.showTime) {
                router.replace({ name: 'GamesWithID', params: { game_id: 'palitra' }});
            }
        }

		/**
		 * Фукнция отображения на канвасе
		 */
		const draw = () => {
            // if (!document.fullscreenElement?.nodeName) {
            //     fullScreenWithoutStatus(html);
            // }
			ctx.value.clearRect(0, 0, canvas?.value?.width ?? 0, canvas?.value?.height ?? 0); //Очистка холста от предыдущего кадра

			if (isBreakpoint.value.status) {
				if (isBreakpoint.value.isDraw) {
					if (moveBackground.value !== undefined) {
						moveBackground.value.move();
					}
					drawBreakpoint();
					moveBackgroundPoint.value?.draw(ctx);
				} else {
					drawBreakpoint();
					moveBackgroundPoint.value?.draw(ctx);
				}
			} else {
				handlerPart();
			}

			ctx.value.fillStyle = "#ffdd58";
			ctx.value.font = "italic 28pt Arial";
			if (typeof starCount.value !== 'undefined') {
				drawObj(starCount.value, starCount.value.scale);
				ctx.value.fillText(`${point.value}`, starCount.value.x + starCount.value.image.width * starCount.value.scale / 2 + 5, starCount.value.y + starCount.value.image.height * starCount.value.scale / 2 - 7);
			}
            if (route.query.showTime) {
                ctx.value.fillStyle = "#ffffff";
                ctx.value.font = "italic 16pt Arial";
                const minutes = Math.floor(game_time.value / 60);
                const seconds = game_time.value - minutes * 60;
                const finalTime = str_pad_left(minutes, '0', 2) + ':' + str_pad_left(seconds, '0', 2);
                ctx.value.fillText(`${finalTime}`, canvas.value.width - 100, 50);
            }

			if (isHit.value) {
				if (!starHit.value) {
					starHit.value = getStarHint.next().value as IShape;
				} else {
					if (typeof starHit.value !== 'boolean') {
						drawObj(starHit.value as IShape, starHit.value.scale);
					}
				}
				if (typeof starHit.value !== 'boolean') {
					if (starHit.value.x + starHit.value.image.width * starHit.value.scale > 0 || starHit.value.y + starHit.value.image.height * starHit.value.scale > 0) {
						starHit.value.y -= 7;
						const indexRectMove = canvas.value.width / canvas.value.height;
						starHit.value.x -= 7 * indexRectMove;
						starHit.value.scale -= 0.001
					} else {
						starHit.value = false;
						isHit.value = false;
						point.value = point.value + 5;
					}
				}
			}

		};

		/**
		 * Функция обновления кадра по таймеру
		 */
		const updateCanvas = () => {
			draw();
		};

		/**
		 * Функция изменения размеров канваса под размеры окна
		 */
		const resize = () => {
			canvas.value.width = window?.innerWidth ?? 0;
			canvas.value.height = window?.innerHeight ?? 0;
		};

		// const checkHit = (shape: Ref<IShape>, player: Ref<IShape>) => {
		//   if ((player.value.x >= shape.value.x - 50 && player.value.x <= shape.value.x + 50) && (player.value.y >= shape.value.y - 50 && player.value.y <= shape.value.y + 50)) {
		//     player.value.dead = true;
		//     shape.value.dead = true;
		//     isHit.value = true;
		//   }
		// };

		const drawBreakpoint = () => {
			if (moveBackground.value !== undefined) {
				ctx.value.drawImage(
					moveBackground.value.image,
					0,
					0,
					moveBackground.value.image.width,
					moveBackground.value.image.height,
					moveBackground.value.x,
					moveBackground.value.y,
					moveBackground.value.image.width * canvas.value.height / moveBackground.value.image.height,
					canvas.value.height
				);
			}
			isBreakpoint.value.isDraw = true
		};

		watch(() => window.innerWidth, resize);
		watch(() => window.innerHeight, resize);

		return {
			resize,
			updateCanvas,
			draw,
			stop,
			start,
			goBack,
			isFullScreen,
			ctx,
			canvas,
            pressedKeys
		}
	}
});

