
import {defineComponent, ref} from 'vue';
import gameVolume from '@/components/game-volume/game-volume.vue';
import gamePalitra from "@/components/game-palitra/game-palitra.vue";

export default defineComponent({
  name: 'GameWithID',
  components: {
    gamePalitra,
    gameVolume,
  },
  setup() {
    const keyPalitra = ref(0);
    const keyVolume = ref(0);

    return {
      keyPalitra,
      keyVolume
    }
  }
});
