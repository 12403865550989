import {Ref} from "vue";
import {IShape} from "@/source/interface/IShape";
import * as resource from "@/resource/volume";
import {arrayRandElement} from "@/source/functions/randomElement";
import {ResourceLenError} from "@/source/class/Errors";

export class Shape {
    x: number
    y: number
    loaded: boolean
    dead: boolean
    isPlayer: boolean
    image: HTMLImageElement
    canvas: HTMLCanvasElement
    speed: number
    scale: number

    constructor(image: string, x: number, y: number, speed: number, isPlayer: boolean, canvas: HTMLCanvasElement, scale: number) {
        this.x = x;
        this.y = y;
        this.loaded = false;
        this.dead = false;
        this.isPlayer = isPlayer;
        this.image = new Image();
        // eslint-disable-next-line
        let obj = this;
        this.image.addEventListener("load", function () { obj.loaded = true; });
        this.image.addEventListener("error", function () { alert('That image was not found.'); });
        this.image.src = image;
        this.canvas = canvas;
        this.speed = speed
        this.scale = scale
    }

    draw(ctx: Ref) {
        ctx.value.drawImage(
            this.image,
            0,
            0,
            this.image.width,
            this.image.height,
            this.x - ((this.image.width * this.scale) / 2),
            this.y - ((this.image.height * this.scale) / 2),
            this.image.width * this.scale,
            this.image.height * this.scale
        );
    }

    Move(vector: string, step: number) {
        // Если перемещаемся по оси х
        if(vector == "x") {
            this.x += step; //Changing position
            //Rolling back the changes if the car left the screen
            if(this.x + (this.image.width * this.scale / 2) > this.canvas.width) {
                this.x -= step;
            }

            if (this.x - (this.image.width * this.scale / 2) < 0) {
                this.x = this.image.width * this.scale / 2 ;
            }
        } else {
            this.y += step;

            if (this.y + (this.image.height * this.scale / 2) > this.canvas.height) {
                this.y -= step;
            }

            if (this.y - (this.image.height * this.scale / 2) < 0) {
                this.y = this.image.height * this.scale / 2;
            }
        }
    }
}


export class GetShapePlayer {
    resourceShape: Array<any>
    resourcePlayer: Array<any>
    canvas: Ref<HTMLCanvasElement>
    speed: number
    scale: Array<number>

    lenResourcesStep: number
    currentScalePStep: number
    currentScaleSStep: number
    currentPlayerStep: number
    currentShapeStep: number
    getShapeG: Generator<IShape>
    shape: IShape
    getPlayerG: Generator<IShape>
    player: IShape
    playerPosArray: Array<{x: number, y: number}>
    getPlayerPosXY: Generator<{x: number, y: number}>

    constructor(resourceShape: Array<any>,
                resourcePlayer: Array<any>,
                canvas: Ref<HTMLCanvasElement>,
                speed: number,
                scale: Array<number>) {
        this.resourceShape = resourceShape;
        this.resourcePlayer = resourcePlayer;
        this.canvas = canvas;
        this.speed = speed;
        this.scale = scale;
        if (resourceShape.length !== resourcePlayer.length) {
            throw new ResourceLenError('Resource red and blue lengths are not equal')
        } else {
            this.lenResourcesStep = resourcePlayer.length;
        }
        this.currentPlayerStep = 0;
        this.currentScalePStep = 0;
        this.currentScaleSStep = 0;
        this.currentShapeStep = 0;
        this.playerPosArray = [
            {'x': 200, 'y': 200},
            {'x': this.canvas.value.width - 200, 'y': 200},
            {'x': this.canvas.value.width - 200, 'y': this.canvas.value.height - 200},
            {'x': 200, 'y': this.canvas.value.height - 200},
        ];
        this.getPlayerPosXY = this.generatorPlayerPosXY();

        this.getPlayerG = this.generatorPlayer();
        this.player = this.getPlayerG.next().value;

        this.getShapeG = this.generatorShape();
        this.shape = this.getShapeG.next().value;
    }

    * generatorPlayerPosXY() {
        while (true) {
            yield arrayRandElement(this.playerPosArray);
        }
    }

    * generatorPlayer() {
        while (true) {
            const lenScale = this.scale.length;
            this.currentPlayerStep = 0;
            while (this.currentPlayerStep <= this.lenResourcesStep - 1) {
                this.currentScalePStep = 0;
                while (this.currentScalePStep <= lenScale - 1) {
                    const coor = this.getPlayerPosXY.next().value
                    yield new Shape(this.resourcePlayer[this.currentPlayerStep], coor.x, coor.y, this.speed, true, this.canvas.value, this.scale[this.currentScalePStep]); //Player's object
                    this.currentScalePStep += 1;
                }
                this.currentPlayerStep += 1;
            }
        }
    }

    * generatorShape() {
        while (true) {
            const lenScale = this.scale.length;
            this.currentShapeStep = 0;
            while (this.currentShapeStep <= this.lenResourcesStep - 1) {
                this.currentScaleSStep = 0;
                while (this.currentScaleSStep <= lenScale - 1) {
                    yield new Shape(this.resourceShape[this.currentShapeStep], this.canvas.value.width / 2, this.canvas.value.height / 2, this.speed, false, this.canvas.value, this.scale[this.currentScaleSStep]); //Player's object
                    this.currentScaleSStep += 1;
                }
                this.currentShapeStep += 1;
            }
        }
    }

    getPlayer() {
        if (this.player.dead) {
            this.player = this.getPlayerG.next().value;
        }
        return this.player;
    }

    getShape() {
        if (this.shape.dead) {
            this.shape = this.getShapeG.next().value;
        }
        return this.shape;
    }

    checkHit(val: Ref<boolean>) {
        if ((this.player.x >= this.shape.x - 50 && this.player.x <= this.shape.x + 50) && (this.player.y >= this.shape.y - 50 && this.player.y <= this.shape.y + 50)) {
            this.player.dead = true;
            this.shape.dead = true;
            return !val.value
        } else {
            return val.value
        }
    }
}


export function useGetShapePlayer(canvas: Ref<HTMLCanvasElement>, speed: number, scale: Array<number>) {
    const resorcePlayer = [
        resource.player1,
        resource.player2,
    ];
    const resorceShape = [
        resource.shape1,
        resource.shape2,
     ];
    return new GetShapePlayer(resorceShape, resorcePlayer, canvas, speed, scale)
}