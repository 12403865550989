
import {computed, defineComponent, onMounted, ref, watch, onUnmounted, Ref, nextTick} from 'vue';
import {Shape, useGetShapePlayer} from "@/source/class/shape";
import {MoveBackground, MoveBackgroundPoint} from "@/source/class/moveBackground";
import {handlerInput, handlerInputTime, setKey} from "@/source/functions/input";
import {
    IMoveBackground,
    IMoveBackgroundPoint,
    IShape,
    IShapeTrackP,
    IShapeTrackS,
    IStarCount
} from "@/source/interface/IShape";
import * as resource from "@/resource/volume";
import {fullScreenWithoutStatus} from "@/source/functions/fullScreen";
import { StarCount } from "@/source/class/starCount";
import { useGetShapeAnimated } from "@/source/class/shapeAnimated";
import {useGetShapeTrackObj} from "@/source/class/shapeTrack";
import { TTrack } from '@/source/type/types';
import {useGetBuilderShape} from "@/source/class/shapeBuilder";
import {useRoute, useRouter} from "vue-router";
import {IKeyboardEvent} from "@/source/interface/IKeyboardEvent";


export default defineComponent({
    name: 'GameVolume',
    props: {
        msg: String,
    },
    setup() {

        onMounted(() => {
            nextTick(() => {
                resize(); // При загрузке страницы задаётся размер холста
                moveBackground.value = new MoveBackground(resource.moveBackground, 2); // Обьект перерыва
                moveBackgroundPoint.value = new MoveBackgroundPoint(resource.moveBackgroundPoint, canvas.value, 0.3) // Обьект перерыва
                starCount.value = new StarCount(resource.starCountCut, 0, 0, speed.value, false, canvas.value, 0.3); //Player's object

                getPlayerShape.value = useGetShapePlayer(canvas, speed.value, scaleShape.value);

                player.value = getPlayerShape.value.getPlayer();
                shape.value = getPlayerShape.value.getShape();

                getShapeAnimated.value = useGetShapeAnimated(canvas, 250,  speed.value, 0.5);
                shapeAnimate.value = getShapeAnimated.value.getShapeAnimated();


                getShapeTrack.value = useGetShapeTrackObj(canvas, speed.value, 250, 0.3, track);
                shapeTrackP.value = getShapeTrack.value.getPlayer();
                shapeTrackS.value = getShapeTrack.value.getShape();

                getShapeBuilder.value = useGetBuilderShape(canvas, speed.value, 0.5, 0.3)
                getShapeBuild.value = getShapeBuilder.value.getBuilder();
                shapeBuild.value = getShapeBuild.value.shapePlayer;

                window.addEventListener("resize", resize); //При изменении размеров окна будут меняться размеры холста
                document.addEventListener('keydown', keyHandlerDown);
                document.addEventListener('keyup', keyHandlerUp);
                window.addEventListener('blur', keyHandlerBlur);

                document.addEventListener("fullscreenchange", fullScreenHandler);
                document.addEventListener("mozfullscreenchange", fullScreenHandler);
                document.addEventListener("webkitfullscreenchange", fullScreenHandler);
                document.addEventListener("msfullscreenchange", fullScreenHandler);
                start();
            });
        });

        onUnmounted(() => {
            stop();
            document.removeEventListener('keydown', keyHandlerDown);
            document.removeEventListener('keyup', keyHandlerUp);
            window.removeEventListener('blur', keyHandlerBlur);
            document.removeEventListener("fullscreenchange", fullScreenHandler);
            document.removeEventListener("mozfullscreenchange", fullScreenHandler);
            document.removeEventListener("webkitfullscreenchange", fullScreenHandler);
            document.removeEventListener("msfullscreenchange", fullScreenHandler);
        });

        const keyHandlerDown = (e: any) => {
            setKey(e, true,  pressedKeys.value);
        }

        const keyHandlerUp = (e: any) => {
            setKey(e, false, pressedKeys.value);
        }

        const keyHandlerBlur = () => {
            pressedKeys.value = {};
        }

        const fullScreenHandler = () => {
            isFullScreen.value = document.fullscreenElement?.nodeName === 'HTML';
        }

        const UPDATE_TIME = 1000 / 120;
        const canvas = ref(); // ссылка на канвас
        const ctx = computed(() => canvas.value.getContext("2d") ); //Получение контекста — через него можно работать с холстом
        const getPlayerShape = ref();
        const player: Ref<IShape | undefined> = ref(); //Объект, которым управляет игрок, — тут будет указан номер объекта в массиве objects
        const shape: Ref<IShape | undefined> = ref(); // Фигура
        const shapeTrackS: Ref<IShapeTrackS | undefined> = ref();
        const shapeTrackP: Ref<IShapeTrackP | undefined> = ref();

        const shapeBuild: Ref<IShape | undefined> = ref(); // Фигура

        const starCount: Ref<IStarCount | undefined> = ref(); // Фигура
        const starHit: Ref<IShape | boolean> = ref(false); // Фигура
        const moveBackground: Ref<IMoveBackground | undefined> = ref();
        const moveBackgroundPoint: Ref<IMoveBackgroundPoint | undefined> = ref();
        const scaleShape = ref([0.8, 0.6, 0.4]);  //Масштаб фигур
        const speed = ref(2);  // Скорость игрока
        const update_timer = ref(); // Счетчик для обновления канваса
        const global_timer = ref(); // Счетчик времени игры
        const time_stop = ref(360); // Время игры в секундах - 6 минут
        const game_time = ref(0);  // Количество времени которое прошло с начала игры
        const pressedKeys = ref({}); // Объект с нажатыми кнопками
        const point = ref(0);
        const breakPoint = [90, 180, 270];
        const breakPointTime = 5;
        const isBreakpoint = ref({status: false, startTime: 0, isDraw: false, countBreakpoint: 0 });
        const isHit = ref(false);
        const isFullScreen = ref(false);
        const shapeAnimate = ref();
        const getShapeAnimated = ref();
        const getShapeTrack = ref();
        const getShapeBuilder = ref();
        const getShapeBuild = ref();
        const track: Ref<TTrack> = computed(() => [[250, 250], [canvas.value.width - 250, 250], [canvas.value.width - 250, canvas.value.height - 250], [ 250, canvas.value.height - 250]])
        const router = useRouter();
        const route = useRoute();
        const html = document.documentElement;

        function * generatorStarHint(): IterableIterator<IShape> {
            while (true) {
                yield new Shape(resource.star, canvas.value.width / 2, canvas.value.height / 2, speed.value, false, canvas.value, 0.1); //Player's object
            }
        }

        function str_pad_left(string: any, pad: any, length: any) {
            return (new Array(length + 1).join(pad) + string).slice(-length);
        }

        function checkShowTime() {
            if (handlerInputTime(pressedKeys.value) && !route.query.showTime) {
                router.replace({ name: 'GamesWithID', params: { game_id: 'volume' }, query: {showTime: 'true'} });
            }
            if (handlerInputTime(pressedKeys.value) && route.query.showTime) {
                router.replace({ name: 'GamesWithID', params: { game_id: 'volume' }});
            }
        }

        const getStarHint = generatorStarHint();

        watch(() => isFullScreen.value, () => {
            if (!isFullScreen.value) {
                stop();
            } else {
                pressedKeys.value = {};
                // fullScreenWithoutStatus(html);
                start();
            }
        });

        const goBack = (isBreak = false) => {
            window.removeEventListener("resize", resize);
            if (isBreak) {
                router.push({ name: 'GamesIntroWithID', params: { game_id: 'volume'}, query: {point: 0, isEnd: 'break'}});
            } else {
                router.push({ name: 'GamesIntroWithID', params: { game_id: 'volume'}, query: {point: point.value, isEnd: 'end'} });
            }
        };

        /**
         * Функция проверяет не истекло ли время игры. Если истекло, то завершает её, если нет - то увеличивает основной счечик.
         */
        const globalTime = () => {
            if (breakPoint.indexOf(game_time.value) !== -1) {
                isBreakpoint.value.status = true;
                isBreakpoint.value.startTime = game_time.value;
                isBreakpoint.value.countBreakpoint += 1;
            }

            if (isBreakpoint.value.status && game_time.value > isBreakpoint.value.startTime + breakPointTime ) {
                isBreakpoint.value.status = false;
                isBreakpoint.value.startTime = 0;
                isBreakpoint.value.isDraw = false;
                if (moveBackground.value !== undefined) {
                    moveBackground.value.x = 0;
                }
            }

            if (game_time.value === time_stop.value) {
                stop();
                goBack();
            } else {
                game_time.value += 1;
            }
        };

        /**
         * Функция запуска игры
         */
        const start = () => {
            isFullScreen.value = true;
            if (!global_timer.value) {
                global_timer.value = setInterval(globalTime, 1000); // Запуск счетчика времени игры, 1 раз за секунду.
            }
            if (!update_timer.value) {
                update_timer.value = setInterval(updateCanvas, UPDATE_TIME); //Состояние игры будет обновляться 60 раз в секунду — при такой частоте обновление происходящего будет казаться очень плавным
            }
        };

        /**
         * Функция остановки игры
         */
        const stop = () => {
            clearInterval(global_timer.value); //Остановка глобального счетчика
            clearInterval(update_timer.value); //Остановка счетчика обновления канваса
            global_timer.value = undefined;
            update_timer.value = undefined;
        };

        /**
         * Функция отображения игрока
         * @param obj
         * @param scale
         */
        const drawObj = (obj: IShape | IStarCount, scale: number) => {
            ctx.value.drawImage(
                obj.image,
                0,
                0,
                obj.image.width,
                obj.image.height,
                obj.x - ((obj.image.width * scale) / 2),
                obj.y - ((obj.image.height * scale) / 2),
                obj.image.width * scale,
                obj.image.height * scale
            );
        };

        const drawPart1 = () => {
            handlerInput(pressedKeys.value, player, speed, isFullScreen);
            isHit.value = getPlayerShape.value.checkHit(isHit);
            player.value = getPlayerShape.value.getPlayer();
            shape.value = getPlayerShape.value.getShape();
            if (shape.value !== undefined) {
                shape.value.draw(ctx);
            }
            if (player.value !== undefined) {
                player.value.draw(ctx);
            }
        }

        const drawPart2 = () => {
            handlerInput(pressedKeys.value, shapeAnimate, speed, isFullScreen);
            isHit.value = getShapeAnimated.value.handlerOutHit(isHit);
            shapeAnimate.value = getShapeAnimated.value.getShapeAnimated();
            shapeAnimate.value.draw(ctx);
        }

        const drawPart3 = () => {
            handlerInput(pressedKeys.value, shapeTrackP, speed, isFullScreen);
            isHit.value = getShapeTrack.value.checkHit(isHit);
            shapeTrackS.value = getShapeTrack.value.getShape();
            shapeTrackP.value = getShapeTrack.value.getPlayer();
            if (shapeTrackS.value !== undefined) {
                shapeTrackS.value?.moveTrack();
                shapeTrackS.value.draw(ctx);
            }
            if (shapeTrackP.value !== undefined) {
                shapeTrackP.value.draw(ctx);
            }
        }

        const drawPart4 = () => {
            handlerInput(pressedKeys.value, shapeBuild, speed, isFullScreen);
            getShapeBuild.value = getShapeBuilder.value.getBuilder();
            if (getShapeBuild.value !== undefined) {
                shapeBuild.value = getShapeBuild.value.getShape();
                isHit.value = getShapeBuild.value.checkHit(isHit);
                getShapeBuild.value.draw(ctx)
            }
        }

        const handlerPart = () => {
            switch (isBreakpoint.value.countBreakpoint) {
                case 0:
                    drawPart1();
                    break;
                case 1:
                    drawPart2();
                    break;
                case 2:
                    drawPart3();
                    break;
                case 3:
                    drawPart4();
                    break;
            }
        }

        /**
         * Фукнция отображения на канвасе
         */
        const draw = () => {
            // if (!document.fullscreenElement?.nodeName) {
            //     fullScreenWithoutStatus(html);
            // }

            ctx.value.clearRect(0, 0, canvas?.value?.width ?? 0, canvas?.value?.height ?? 0); //Очистка холста от предыдущего кадра

            ctx.value.fillStyle = "#ffdd58";
            ctx.value.font = "italic 28pt Arial";
            if (typeof starCount.value !== 'undefined') {
                drawObj(starCount.value, starCount.value.scale);
                ctx.value.fillText(`${point.value}`, starCount.value.x + starCount.value.image.width * starCount.value.scale / 2 + 5, starCount.value.y + starCount.value.image.height * starCount.value.scale / 2 - 7);
            }

            if (route.query.showTime) {
                ctx.value.fillStyle = "#ffffff";
                ctx.value.font = "italic 16pt Arial";
                const minutes = Math.floor(game_time.value / 60);
                const seconds = game_time.value - minutes * 60;
                const finalTime = str_pad_left(minutes, '0', 2) + ':' + str_pad_left(seconds, '0', 2);
                ctx.value.fillText(`${finalTime}`, canvas.value.width - 100, 50);
            }

            if (isHit.value) {
                if (!starHit.value) {
                    starHit.value = getStarHint.next().value as IShape;
                } else {
                    if (typeof starHit.value !== 'boolean') {
                        drawObj(starHit.value as IShape, starHit.value.scale);
                    }
                }
                if (typeof starHit.value !== 'boolean') {
                    if (starHit.value.x + starHit.value.image.width * starHit.value.scale > 0 || starHit.value.y + starHit.value.image.height * starHit.value.scale > 0) {
                        starHit.value.y -= 7;
                        const indexRectMove = canvas.value.width / canvas.value.height;
                        starHit.value.x -= 7 * indexRectMove;
                        starHit.value.scale -= 0.001
                    } else {
                        starHit.value = false;
                        isHit.value = false;
                        point.value = point.value + 5;
                    }
                }
            }

            if (isBreakpoint.value.status) {
                if (isBreakpoint.value.isDraw) {
                    if (moveBackground.value !== undefined) {
                        moveBackground.value.move();
                    }
                    drawBreakpoint();
                    moveBackgroundPoint.value?.draw(ctx);
                } else {
                    drawBreakpoint();
                    moveBackgroundPoint.value?.draw(ctx);
                }
            } else {
                handlerPart();
            }
        };

        /**
         * Функция обновления кадра по таймеру
         */
        const updateCanvas = () => {
            draw();
        };

        /**
         * Функция изменения размеров канваса под размеры окна
         */
        const resize = () => {
            // canvas.value.width = window.innerWidth;
            canvas.value.width = document.documentElement.clientWidth;
            // canvas.value.height = window.innerHeight;
            canvas.value.height = document.documentElement.clientHeight;
        };


        // const checkHit = (shape: Ref<IShape>, player: Ref<IShape>) => {
        //   if ((player.value.x >= shape.value.x - 50 && player.value.x <= shape.value.x + 50) && (player.value.y >= shape.value.y - 50 && player.value.y <= shape.value.y + 50)) {
        //     player.value.dead = true;
        //     shape.value.dead = true;
        //     isHit.value = true;
        //   }
        // };

        const drawBreakpoint = () => {
            if (moveBackground.value !== undefined) {
                ctx.value.drawImage(
                    moveBackground.value.image,
                    0,
                    0,
                    moveBackground.value.image.width,
                    moveBackground.value.image.height,
                    moveBackground.value.x,
                    moveBackground.value.y,
                    moveBackground.value.image.width,
                    moveBackground.value.image.height
                );
            }
            isBreakpoint.value.isDraw = true
        };

        watch(() => window.innerWidth, resize);
        watch(() => window.innerHeight, resize);

        return {
            resize,
            updateCanvas,
            draw,
            stop,
            start,
            goBack,
            isFullScreen,
            player,
            ctx,
            canvas
        }
    }
});

