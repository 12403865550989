import {Ref} from "vue";
import { TObject } from "@/source/type/types"
import * as resource from "@/resource/volume";
import {IShapeAnimate} from "@/source/interface/IShape";
import {ResourceLenError} from "@/source/class/Errors";


export class ShapeAnimated {
    x1: number  // Позиция на карте изображения 1 х
    y1: number  // Позиция на карте изображения 1 y
    x2: number  // Позиция на карте изображения 1 х
    y2: number  // Позиция на карте изображения 1 y
    image1: HTMLImageElement // Изображение 1
    image2: HTMLImageElement // Изображение 2
    pos1: Array<number>  // позиция x,y изображения на спрайт карте
    pos2: Array<number>  // позиция x,y изображения на спрайт карте
    size1: Array<number>  // Размеры одного изображения на спрайт карте x,y
    size2: Array<number>  // Размеры одного изображения на спрайт карте x,y
    lenFrames1: number // колличество кадров на спрайт карте
    lenFrames2: number // колличество кадров на спрайт карте
    speedAnimate: number  // Скорость перемещения по спрайт карте
    loaded1: boolean  // Статус загрузки изображений
    loaded2: boolean  // Статус загрузки изображений
    isHit: boolean  // Статус сопастовления
    canvas: HTMLCanvasElement  // Канвас
    speed: number  // Скорость
    scale: number  // Масштаб
    isAnimated: boolean
    timer: number

    constructor(
        image1: string,
        image2: string,
        posCanvas1: Array<number>,
        posCanvas2: Array<number>,
        lenFrames1: number,
        lenFrames2: number,
        speedAnimate: number,
        canvas: HTMLCanvasElement,
        speed: number,
        scale: number
    ) {
        [this.x1, this.y1] = posCanvas1;
        [this.x2, this.y2] = posCanvas2;
        this.loaded1 = false;
        this.loaded2 = false;
        this.isHit = false;
        this.lenFrames1 = lenFrames1;
        this.lenFrames2 = lenFrames2;
        this.pos1 = [0, 0];
        this.pos2 = [0, 0];
        this.size1 = [0, 0];
        this.size2 = [0, 0];
        this.isAnimated = false;
        this.speedAnimate = speedAnimate;
        this.canvas = canvas;
        this.speed = speed;
        this.scale = scale;
        this.image1 = new Image();
        this.image2 = new Image();
        // eslint-disable-next-line
        let obj = this;
        this.image1.addEventListener("load", function () {
            obj.loaded1 = true;
            obj.size1 = [obj.image1.width / obj.lenFrames1, obj.image1.height];
        });
        this.image1.addEventListener("error", function () { console.log('That image1 was not found.'); });
        this.image2.addEventListener("load", function () {
            obj.loaded2 = true;
            obj.size2 = [obj.image2.width / obj.lenFrames2, obj.image2.height];
            obj.x2 = obj.x2 - obj.size2[0] * obj.scale;
            obj.y2 = obj.y2 - obj.size2[1] * obj.scale;
        });
        this.image2.addEventListener("error", function () { console.log('That image2 was not found.'); });
        this.image1.src = image1;
        this.image2.src = image2;

        this.timer = this.startAnimate();
    }

    startAnimate() {
        this.isAnimated = true;
        let curentFrame1 = 0;
        let curentFrame2 = 0;
        return setInterval(() => {
            this.pos1[0] = this.size1[0] * curentFrame1;
            this.pos1[1] = 0;

            this.pos2[0] = this.size2[0] * curentFrame2;
            this.pos2[1] = 0;

            if (curentFrame1 < this.lenFrames1 - 1) {
                curentFrame1 += 1;
            } else {
                curentFrame1 = 0;
            }

            if (curentFrame2 < this.lenFrames2 - 1) {
                curentFrame2 += 1;
            } else {
                curentFrame2 = 0;
            }
        }, this.speedAnimate);
    }

    draw(ctx: Ref) {
            ctx.value.drawImage(
                this.image1,
                this.pos1[0],
                this.pos1[1],
                this.size1[0],
                this.size1[1],
                this.x1,
                this.y1,
                this.size1[0] * this.scale,
                this.size1[1] * this.scale
            );
            ctx.value.drawImage(
                this.image2,
                this.pos2[0],
                this.pos2[1],
                this.size2[0],
                this.size2[1],
                this.x2,
                this.y2,
                this.size2[0] * this.scale,
                this.size2[1] * this.scale
            );
    }

    Move(vector: string, step: number) {
        // Если перемещаемся по оси х
        if(vector == "x") {
            this.x1 -= step;
            this.x2 += step; //Changing position

            if(this.x1 + (this.size2[0] * this.scale) > this.canvas.width || this.x2 + (this.size1[0] * this.scale) > this.canvas.width) {
                this.x1 += step;
                this.x2 -= step;
            }

        } else {
            this.y1 -= step;
            this.y2 += step;

            if(this.y1 + (this.size2[1] * this.scale) > this.canvas.height || this.y2 + (this.size1[1] * this.scale) > this.canvas.height) {
                this.y1 += step;
                this.y2 -= step;
            }
        }

        if ((this.x1 >= this.x2 - 10 && this.x1 <= this.x2 + 10) && (this.y1 >= this.y2 - 10 && this.y1 <= this.y2 +10)) {
            this.isHit = true;
        }
    }
}

export class GetShapeAnimated {
    resourceBlue: Array<any>
    resourceRed: Array<any>
    currentStep: number
    lenResourcesStep: number
    canvas: Ref<HTMLCanvasElement>
    speedAnimate: number
    speed: number
    scale: number
    getShape: Generator<IShapeAnimate>
    shapeAnimated: IShapeAnimate

    constructor(resourceBlue: Array<any>, resourceRed: Array<any>, canvas: Ref<HTMLCanvasElement>, speedAnimate: number, speed: number, scale: number) {
        this.resourceBlue = resourceBlue;
        this.resourceRed = resourceRed;
        this.canvas = canvas;
        this.speedAnimate = speedAnimate;
        this.speed = speed;
        this.scale = scale;
        if (resourceRed.length !== resourceBlue.length) {
            throw new ResourceLenError('Resource red and blue lengths are not equal')
        } else {
            this.currentStep = 0;
            this.lenResourcesStep = resourceBlue.length;
        }
        this.getShape = this.generatorShapeAnimated();
        this.shapeAnimated = this.getShape.next().value;
    }

    * generatorShapeAnimated() {
        while (true) {
            while (this.currentStep < this.lenResourcesStep) {
                yield new ShapeAnimated(
                    this.resourceBlue[this.currentStep],
                    this.resourceRed[this.currentStep],
                    [50, 50],
                    [this.canvas.value.width - 50, this.canvas.value.height - 50],
                    2,
                    2,
                    this.speedAnimate,
                    this.canvas.value,
                    this.speed,
                    this.scale);
                this.currentStep += 1;
            }
            this.currentStep = 0;
        }
    }

    handlerOutHit(val: Ref<boolean>) {
        if (this.shapeAnimated.isHit) {
            return !val.value
        } else {
            return val.value
        }
    }

    getShapeAnimated() {
        if (this.shapeAnimated.isHit) {
            this.shapeAnimated = this.getShape.next().value;
        }
        return this.shapeAnimated
    }
}

export function useGetShapeAnimated(canvas: Ref<HTMLCanvasElement>, speedAnimate: number, speed: number, scale: number) {
    const resorceBlue = [
        resource.animal1blue, 
        resource.animal2blue, 
        resource.animal3blue, 
        resource.animal4blue, 
        resource.animal5blue, 
        resource.animal6blue, 
        resource.animal7blue, 
        resource.animal8blue, 
        resource.animal9blue, 
        resource.animal10blue, 
        resource.animal11blue, 
        resource.animal12blue];
    const resorceRed = [
        resource.animal1red,
        resource.animal2red,
        resource.animal3red,
        resource.animal4red,
        resource.animal5red,
        resource.animal6red,
        resource.animal7red,
        resource.animal8red,
        resource.animal9red,
        resource.animal10red,
        resource.animal11red,
        resource.animal12red];
    return new GetShapeAnimated(resorceBlue, resorceRed, canvas, speedAnimate, speed, scale)
}