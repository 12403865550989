import { IKeyboardEvent } from "@/source/interface/IKeyboardEvent";
import {Ref} from "vue";
import {fullScreenWithoutStatus} from "@/source/functions/fullScreen";

export const setKey = (e: IKeyboardEvent, status: boolean, obj: {[index: string]:any}): void => {
    const code = e.keyCode;
    let key: string;

    switch(code) {
        case 32:
            key = 'SPACE'; break;
        case 37:
            key = 'LEFT'; break;
        case 38:
            key = 'UP'; break;
        case 39:
            key = 'RIGHT'; break;
        case 40:
            key = 'DOWN'; break;
        case 13:
            key = 'ENTER'; break;
        case 27:
            key = 'ESC'; break;
        case 84:
            key = 'T'; break;
        default:
            // Convert ASCII codes to letters
            key = String.fromCharCode(code);
    }
    obj[key] = status;
};

export const getKey = (key: string, obj: {[index: string]:any}): any | boolean => {
    if (key.toUpperCase() in obj) {
        return obj[key.toUpperCase()]
    } else { return false }
};

export const handlerInput = (obj: {[index: string]:any}, player: any, speed: any, fullScreenStatus: Ref<boolean>): void => {
    if (getKey('DOWN', obj) || getKey('s', obj)) {
        player.value?.Move('y', speed.value);
    }

    if (getKey('UP', obj) || getKey('w', obj)) {
        player.value.Move('y', -speed.value);
    }

    if (getKey('LEFT', obj) || getKey('a', obj)) {
        player.value.Move('x', -speed.value);
    }

    if (getKey('RIGHT', obj) || getKey('d', obj)) {
        player.value.Move('x', speed.value);
    }

    if (getKey('SPACE', obj)) {
        fullScreenStatus.value = !fullScreenStatus.value;
    }

};

export const handlerInputBlaster = (obj: {[index: string]:any}, blaster: any, speed: any): void => {
    if (getKey('DOWN', obj) || getKey('s', obj)) {
        blaster.value?.move('y', speed.value);
    }

    if (getKey('UP', obj) || getKey('w', obj)) {
        blaster.value.move('y', -speed.value);
    }

    if (getKey('SPACE', obj)) {
        blaster.value.isShot = true;
    }
};

export const handlerInputTime = (obj: {[index: string]:any}):boolean => {
    return getKey('T', obj);
}

export const handlerInputFullScreen = (obj: {[index: string]:any}) => {
    if (getKey('SPACE', obj)) {
        const html = document.documentElement;
        fullScreenWithoutStatus(html);
    }
}