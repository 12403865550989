import {Ref} from "vue";

export function fullScreen(element: any, status: Ref<boolean>): void {
    if(element.requestFullscreen) {
        element.requestFullscreen();
    } else if(element.webkitrequestFullscreen) {
        element.webkitRequestFullscreen();
    } else if(element.mozRequestFullscreen) {
        element.mozRequestFullScreen();
    }
    status.value = true;
}

export function fullScreenWithoutStatus(element: any): void {
    const rfs = element.requestFullscreen || element.webkitRequestFullScreen || element.mozRequestFullScreen || element.msRequestFullscreen;
    rfs.call(element);
}

export function exitFullScreen(): void {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    try {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        if ((window['fullScreen']) || (window.innerWidth == screen.width && window.innerHeight == screen.height)) {
            if (document?.exitFullscreen) {
                document?.exitFullscreen();
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
            } else if (document?.webkitExitFullscreen) {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                document?.webkitExitFullscreen();
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
            } else if (document?.mozCancelFullScreen) {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                document?.mozCancelFullScreen();
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
            } else if (document?.msExitFullscreen) {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                document?.msExitFullscreen();
            }
        }
    } catch(e) {
        console.log(e);
    }
}