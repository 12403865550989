import {Ref} from "vue";
import {IMovePalitraBackground, IMovePalitraBackgroundPointArea} from "@/source/interface/IShape";
import * as resource from "@/resource/palitra";
import {
    TResourcePalitraMove,
    TResourcePalitraMoveArray,
    TResourcePalitraMovePointArea,
    TResourcePalitraMovePointAreaArray
} from "@/source/type/types";

export class MovePalitraBackground {
    x: number
    y: number
    isLoadedStart: boolean
    isLoadedEnd: boolean
    isDead: boolean
    imageStart: HTMLImageElement
    imageEnd: HTMLImageElement
    speed: number
    speedAnimate: number
    canvas: HTMLCanvasElement
    pointX: number
    pointY: number
    isAnimated: boolean
    timer: number

    constructor(imageStart: string, imageEnd: string, canvas: HTMLCanvasElement, speed: number, speedAnimate: number, coorPoint: [number, number]) {
        this.isLoadedStart = false;
        this.isLoadedEnd = false;
        this.isAnimated = false;
        this.canvas = canvas;
        this.isDead = false;
        this.x = 0;
        this.y = 0;
        this.pointX = 0;
        this.pointY = 0;
        this.imageStart = new Image();
        this.imageEnd = new Image();
        this.speed = speed;
        this.speedAnimate = speedAnimate;
        // eslint-disable-next-line
        let obj: this = this;
        this.imageStart.addEventListener("load", function () {
            obj.isLoadedStart = true;
            const imageWidth = obj.imageStart.width;
            const imageHeight = obj.imageStart.height;
            const newImageHeight = obj.canvas.height;
            const newImageWidth = obj.imageStart.width * obj.canvas.height / obj.imageStart.height;

            const ratioX = newImageWidth / imageWidth;
            const ratioY = newImageHeight / imageHeight;

            obj.pointX = coorPoint[0] * ratioX;
            obj.pointY = coorPoint[1] * ratioY;
        });
        this.imageStart.src = imageStart;
        this.imageEnd.addEventListener("load", function () { obj.isLoadedEnd = true; });
        this.imageEnd.src = imageEnd;
        this.timer = 1;
    }

    startAnimate(): number {
        this.isAnimated = true;
        return setInterval(() => {
            this.move()
        }, this.speedAnimate);
    }

    draw(ctx: Ref): void {
        ctx.value.drawImage(
            this.imageStart,
            0,
            0,
            this.imageStart.width,
            this.imageStart.height,
            this.x,
            this.y,
            this.imageStart.width * this.canvas.height / this.imageStart.height,
            this.canvas.height
        );
        ctx.value.drawImage(
            this.imageEnd,
            0,
            0,
            this.imageEnd.width,
            this.imageEnd.height,
            this.x + this.imageStart.width * this.canvas.height / this.imageStart.height,
            0,
            this.imageStart.width * this.canvas.height / this.imageStart.height,
            this.canvas.height
        );
    }

    move(): boolean {
        this.x -= this.speed;
        this.pointX -= this.speed;
        if (this.pointX + 200 <= 0) {
            this.isDead = true;
        }
        if (this.imageStart.width + this.x <= 0) {
            this.isDead = true;
        }
        return this.isDead
    }
}


export class MovePalitraBackgroundPointArea {
    x: number
    y: number
    isLoadedStart: boolean
    isLoadedEnd: boolean
    isDead: boolean
    imageStart: HTMLImageElement
    imageEnd: HTMLImageElement
    speed: number
    canvas: HTMLCanvasElement
    pointX: number
    pointY: number
    pointHeight: number
    pointWidth: number
    isStop: boolean

    constructor(imageStartSrc: string, imageEndSrc: string, canvas: HTMLCanvasElement, speed: number, coorPoint: [number, number], pointWidth: number, pointHeight: number) {
        this.isLoadedStart = false;
        this.isLoadedEnd = false;
        this.isDead = false;
        this.isStop = false;
        this.canvas = canvas;
        this.pointHeight = 0;
        this.pointWidth = 0;
        this.x = 0;
        this.y = 0;
        this.pointX = 0;
        this.pointY = 0;
        this.imageStart = new Image();
        this.imageEnd = new Image();
        this.speed = speed;
        // eslint-disable-next-line
        let obj: this = this;
        this.imageStart.addEventListener("load", function () {
            obj.isLoadedStart = true;
            const imageWidth = obj.imageStart.width;
            const imageHeight = obj.imageStart.height;
            const newImageHeight = obj.canvas.height;
            const newImageWidth = obj.imageStart.width * obj.canvas.height / obj.imageStart.height;

            const ratioX = newImageWidth / imageWidth;
            const ratioY = newImageHeight / imageHeight;

            obj.pointX = coorPoint[0] * ratioX;
            obj.pointY = coorPoint[1] * ratioY;

            obj.pointHeight = pointHeight * ratioY;
            obj.pointWidth = pointWidth * ratioX;
        });
        this.imageStart.src = imageStartSrc;
        this.imageEnd.addEventListener("load", function () { obj.isLoadedEnd = true; });
        this.imageEnd.src = imageEndSrc;
    }

    /**
     * Отрисовывает объекты на канвасе
     * @param {Ref} ctx
     */
    draw(ctx: Ref): void {
        ctx.value.drawImage(
            this.imageStart,
            0,
            0,
            this.imageStart.width,
            this.imageStart.height,
            this.x,
            this.y,
            this.imageStart.width * this.canvas.height / this.imageStart.height,
            this.canvas.height
        );
        ctx.value.drawImage(
            this.imageEnd,
            0,
            0,
            this.imageEnd.width,
            this.imageEnd.height,
            this.x + this.imageStart.width * this.canvas.height / this.imageStart.height,
            0,
            this.imageStart.width * this.canvas.height / this.imageStart.height,
            this.canvas.height
        );
    }

    /**
     * Проверяет входят ли координаты, в область точки.
     * Меняет сос
     * @param {Ref<boolean>} valCheck
     * @param {number} shotX
     * @param {number} shotY
     * @returns {boolean}
     */
    checkHit(valCheck: Ref<boolean>, shotX: number, shotY: number) {
        const pointLeft = this.pointX - this.pointWidth / 2;
        const pointRight = this.pointX + this.pointWidth / 2;
        const pointTop = this.pointY - this.pointHeight / 2;
        const pointBottom = this.pointY + this.pointHeight / 2;
        if (shotX > pointLeft && shotX < pointRight && shotY < pointBottom && shotY > pointTop) {
            if (!valCheck.value) {
                valCheck.value = !valCheck.value;
            }
            this.isStop = true;
            return valCheck.value
        } else {
            return valCheck.value
        }
    }

    /**
     * Перемещает объект, если он не остановлен
     * @returns {boolean}
     */
    move() {
        if (this.isStop) {
            return this.isDead
        }
        this.x -= this.speed;
        this.pointX -= this.speed;
        if (this.pointX + 200 <= 0) {
            this.isDead = true;
        }
        if (this.imageStart.width + this.x <= 0) {
            this.isDead = true;
        }
        return this.isDead
    }
}


export class GetMovePalitraBackground {
    resourcePalitra: TResourcePalitraMoveArray
    currentStep: number
    lenResourcesStep: number
    canvas: Ref<HTMLCanvasElement>
    speedAnimate: number
    speed: number
    getMovePalitraBackground: Generator<IMovePalitraBackground>
    movePalitraBackground: IMovePalitraBackground

    constructor(resourcePalitra: TResourcePalitraMoveArray, canvas: Ref<HTMLCanvasElement>, speedAnimate: number, speed: number) {
        this.resourcePalitra = resourcePalitra;
        this.canvas = canvas;
        this.speedAnimate = speedAnimate;
        this.speed = speed;
        this.lenResourcesStep = resourcePalitra.length;
        this.currentStep = 0;
        this.getMovePalitraBackground = this.generatorMovePalitraBackground();
        this.movePalitraBackground = this.getMovePalitraBackground.next().value;
    }

    * generatorMovePalitraBackground(): Generator<IMovePalitraBackground> {
        while (true) {
            this.currentStep = 0;
            while (this.currentStep < this.lenResourcesStep) {
                yield new MovePalitraBackground(
                    this.resourcePalitra[this.currentStep].resourceStart,
                    this.resourcePalitra[this.currentStep].resourceEnd,
                    this.canvas.value,
                    this.speed,
                    this.speedAnimate,
                    this.resourcePalitra[this.currentStep].coorPoint,
                );
                this.currentStep += 1;
            }
        }
    }

    getResourse(): IMovePalitraBackground {
        // console.log('getResourse background', this.movePalitraBackground.isDead)
        if (this.movePalitraBackground.isDead) {
            this.movePalitraBackground = this.getMovePalitraBackground.next().value;
        }
        return this.movePalitraBackground
    }
}


export class GetMovePalitraBackgroundPointArea {
    resourcePalitra: TResourcePalitraMovePointAreaArray
    currentStep: number
    lenResourcesStep: number
    canvas: Ref<HTMLCanvasElement>
    speed: number
    getMovePalitraBackgroundPointArea: Generator<IMovePalitraBackgroundPointArea>
    movePalitraBackground: IMovePalitraBackgroundPointArea

    constructor(resourcePalitra: TResourcePalitraMovePointAreaArray, canvas: Ref<HTMLCanvasElement>, speed: number) {
        this.resourcePalitra = resourcePalitra;
        this.canvas = canvas;
        this.speed = speed;
        this.lenResourcesStep = resourcePalitra.length;
        this.currentStep = 0;
        this.getMovePalitraBackgroundPointArea = this.generatorMovePalitraBackgroundPointArea();
        this.movePalitraBackground = this.getMovePalitraBackgroundPointArea.next().value;
    }

    * generatorMovePalitraBackgroundPointArea(): Generator<IMovePalitraBackgroundPointArea> {
        while (true) {
            this.currentStep = 0;
            while (this.currentStep < this.lenResourcesStep) {
                yield new MovePalitraBackgroundPointArea(
                    this.resourcePalitra[this.currentStep].resourceStart,
                    this.resourcePalitra[this.currentStep].resourceEnd,
                    this.canvas.value,
                    this.speed,
                    this.resourcePalitra[this.currentStep].coorPoint,
                    this.resourcePalitra[this.currentStep].pointWidth,
                    this.resourcePalitra[this.currentStep].pointHeight,
                );
                this.currentStep += 1;
            }
        }
    }

    getResources(): IMovePalitraBackgroundPointArea {
        if (this.movePalitraBackground.isDead) {
            this.movePalitraBackground = this.getMovePalitraBackgroundPointArea.next().value;
        }
        return this.movePalitraBackground
    }
}


export function useGetMovePalitraBackground(canvas: Ref<HTMLCanvasElement>, speedAnimate: number, speed: number): GetMovePalitraBackground {
    const properties = require('../../resource/palitra/properties.json');
    const resourcePalitra: TResourcePalitraMoveArray = []

    for (let i = 1; i < 61; i++) {
        const temp: TResourcePalitraMove = {
            resourceStart: resource[`palitraStart${i}` as keyof typeof resource],
            resourceEnd: resource[`palitraEnd${i}` as keyof typeof resource],
            coorPoint: [properties[`${i}`].point.x, properties[`${i}`].point.y]
        };
        resourcePalitra.push(temp);
    }

    return new GetMovePalitraBackground(resourcePalitra, canvas, speedAnimate, speed);
}


export function useGetMovePalitraBackgroundForBlaster(canvas: Ref<HTMLCanvasElement>, speed: number): GetMovePalitraBackgroundPointArea {
    const properties = require('../../resource/palitra/properties.json');
    const resourcePalitra: TResourcePalitraMovePointAreaArray = []

    for (let i = 61; i < 71; i++) {
        const temp: TResourcePalitraMovePointArea = {
            resourceStart: resource[`palitraStart${i}` as keyof typeof resource],
            resourceEnd: resource[`palitraEnd${i}` as keyof typeof resource],
            coorPoint: [properties[`${i}`].point.x, properties[`${i}`].point.y],
            pointWidth: properties[`${i}`].point.width,
            pointHeight: properties[`${i}`].point.height,
        };
        resourcePalitra.push(temp);
    }

    return new GetMovePalitraBackgroundPointArea(resourcePalitra, canvas, speed);
}

