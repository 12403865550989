import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-237403c2"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "games-with-id" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_game_volume = _resolveComponent("game-volume")!
  const _component_game_palitra = _resolveComponent("game-palitra")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.$route.params?.game_id === 'volume')
      ? (_openBlock(), _createBlock(_component_game_volume, { key: _ctx.keyVolume }))
      : _createCommentVNode("", true),
    (_ctx.$route.params?.game_id === 'palitra')
      ? (_openBlock(), _createBlock(_component_game_palitra, { key: _ctx.keyPalitra }))
      : _createCommentVNode("", true)
  ]))
}