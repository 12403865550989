import {Ref} from "vue";
import * as resource from "@/resource/palitra";
import {IMovePalitraBackground, IShapeAIM} from "@/source/interface/IShape";

export class ShapeAIM {
    x: number  // Позиция на карте изображения 1 х
    y: number  // Позиция на карте изображения 1 y
    pointX: number
    pointY: number
    image: HTMLImageElement // Изображение 1
    pos: Array<number>  // позиция x, y изображения на спрайт карте
    size: Array<number>  // Размеры одного изображения на спрайт карте x, y
    lenFrames: number // колличество кадров на спрайт карте
    speedAnimate: number  // Скорость перемещения по спрайт карте
    isLoaded: boolean  // Статус загрузки изображений
    isHit: boolean  // Статус сопастовления
    canvas: HTMLCanvasElement  // Канвас
    speed: number  // Скорость
    scale: number  // Масштаб
    isAnimated: boolean
    timer: number

    constructor(
        image: string,
        posCanvas: Array<number>,
        lenFrames: number,
        speedAnimate: number,
        canvas: HTMLCanvasElement,
        speed: number,
        scale: number
    ) {
        [this.x, this.y] = posCanvas;
        this.isLoaded = false;
        this.isHit = false;
        this.lenFrames = lenFrames;
        this.pos = [0, 0];
        this.size = [0, 0];
        this.isAnimated = false;
        this.speedAnimate = speedAnimate;
        this.canvas = canvas;
        this.speed = speed;
        this.scale = scale;
        this.image = new Image();
        this.pointX = 0;
        this.pointY = 0;
        // eslint-disable-next-line
        let obj = this;
        this.image.addEventListener("load", function () {
            obj.isLoaded = true;
            obj.size = [obj.image.width / obj.lenFrames, obj.image.height];
            obj.x = 50;
            obj.y = obj.canvas.height - obj.image.height * obj.scale - 50;
            obj.pointX = (obj.image.width / obj.lenFrames) * obj.scale / 2;
            obj.pointY = obj.image.height * obj.scale / 2;
        });
        this.image.addEventListener("error", function () { console.log('That image1 was not found.'); });
        this.image.src = image;
        this.timer = this.startAnimate();
    }

    startAnimate() {
        this.isAnimated = true;
        let curentFrame = 0;
        return setInterval(() => {
            this.pos[0] = this.size[0] * curentFrame;
            this.pos[1] = 0;
            if (curentFrame < this.lenFrames - 1) {
                curentFrame += 1;
            } else {
                curentFrame = 0;
            }
        }, this.speedAnimate);
    }

    checkHit(obj: IMovePalitraBackground, val: Ref<boolean>) {
        const x = obj.pointX;
        const y = obj.pointY;
        const curentX = this.x + this.pointX;
        const curentY = this.y + this.pointY;
        if ((curentX >= x - 25 && curentX <= x + 25) && (curentY >= y - 25 && curentY <= y + 25)) {
            this.isHit = true;
            obj.isDead = true;
            return !val.value
        } else {
            return val.value
        }
    }

    draw(ctx: Ref) {
        ctx.value.drawImage(
            this.image,
            this.pos[0],
            this.pos[1],
            this.size[0],
            this.size[1],
            this.x,
            this.y,
            this.size[0] * this.scale,
            this.size[1] * this.scale
        );
    }

    Move(vector: string, step: number) {
        // Если перемещаемся по оси х
        const imHeight = this.image.height * this.scale;
        const imgWight = this.image.width * this.scale / this.lenFrames;
        if(vector == "x") {
            this.x += step; //Changing position
            //Rolling back the changes if the car left the screen
            if(this.x + imgWight / 2  > this.canvas.width) {
                this.x -= step;
            }
            if(this.x < 0 - imgWight / 2 ) {
                this.x -= step;
            }
        } else {
            this.y += step;

            if(this.y + imHeight / 2 > this.canvas.height) {
                this.y -= step;
            }

            if(this.y < 0 - imHeight / 2) {
                this.y -= step;
            }
        }
    }
}

export class GetShapeAIM {
    resource: any
    lenFrames: number
    canvas: Ref<HTMLCanvasElement>
    speedAnimate: number
    speed: number
    scale: number
    getShape: Generator<IShapeAIM>
    shapeAIM: IShapeAIM

    constructor(resource: any, lenFrames: number, canvas: Ref<HTMLCanvasElement>, speedAnimate: number, speed: number, scale: number) {
        this.resource = resource;
        this.lenFrames = lenFrames;
        this.canvas = canvas;
        this.speedAnimate = speedAnimate;
        this.speed = speed;
        this.scale = scale;
        this.getShape = this.generatorShapeAnimated();
        this.shapeAIM = this.getShape.next().value;
    }

    * generatorShapeAnimated(): Generator<IShapeAIM> {
        while (true) {
            yield new ShapeAIM(this.resource,
                [50, 50],
                this.lenFrames,
                this.speedAnimate,
                this.canvas.value,
                this.speed,
                this.scale);
        }
    }

    getShapeAIM() {
        if (this.shapeAIM.isHit) {
            this.shapeAIM = this.getShape.next().value;
        }
        return this.shapeAIM
    }
}

export function useGetShapeAIM(canvas: Ref<HTMLCanvasElement>, speedAnimate: number, speed: number, scale: number) {
    return new GetShapeAIM(resource.aim, 5, canvas, speedAnimate, speed, scale)
}