import { Ref} from "vue";
import {IBuilderShape, IShape} from "@/source/interface/IShape";
import * as resource from "@/resource/volume";
import { arrayRandElement } from "@/source/functions/randomElement";
import { Shape } from "@/source/class/shape";
import { ResourceLenError } from "@/source/class/Errors";

// export class Shape {
//     x: number
//     y: number
//     loaded: boolean
//     dead: boolean
//     isPlayer: boolean
//     image: HTMLImageElement
//     canvas: HTMLCanvasElement
//     speed: number
//     scale: number
//
//     constructor(image: string, x: number, y: number, speed: number, isPlayer: boolean, canvas: HTMLCanvasElement, scale: number) {
//         this.x = x;
//         this.y = y;
//         this.loaded = false;
//         this.dead = false;
//         this.isPlayer = isPlayer;
//         this.image = new Image();
//         // eslint-disable-next-line
//         let obj = this;
//         this.image.addEventListener("load", function () { obj.loaded = true; });
//         this.image.addEventListener("error", function () { alert('That image was not found.'); });
//         this.image.src = image;
//         this.canvas = canvas;
//         this.speed = speed
//         this.scale = scale
//     }
//
//     draw(ctx: Ref) {
//         ctx.value.drawImage(
//             this.image,
//             0,
//             0,
//             this.image.width,
//             this.image.height,
//             this.x - ((this.image.width * this.scale) / 2),
//             this.y - ((this.image.height * this.scale) / 2),
//             this.image.width * this.scale,
//             this.image.height * this.scale
//         );
//     }
//
//     Move(vector: string, step: number) {
//         if(vector == "x") {
//             this.x += step;
//             if(this.x + (this.image.width * this.scale / 2) > this.canvas.width) {
//                 this.x -= step;
//             }
//
//             if(this.x - (this.image.width * this.scale / 2) < 0) {
//                 this.x = this.image.width * this.scale / 2 ;
//             }
//         } else {
//             this.y += step;
//
//             if(this.y + (this.image.height * this.scale / 2) > this.canvas.height) {
//                 this.y -= step;
//             }
//
//             if(this.y - (this.image.height * this.scale / 2) < 0) {
//                 this.y = this.image.height * this.scale / 2;
//             }
//         }
//
//     }
// }

export class ShapeExample extends Shape {

    constructor(image: string, x: number, y: number, speed: number, isPlayer: boolean, canvas: HTMLCanvasElement, scale: number) {
        super(image, x, y, speed, isPlayer, canvas, scale);
        this.image = new Image();
        // eslint-disable-next-line
        let obj = this;
        this.image.addEventListener("load", function () {
            obj.loaded = true;
            obj.x = obj.canvas.width - obj.image.width * obj.scale - 50;
        });
        this.image.addEventListener("error", function () { alert('That image was not found.'); });
        this.image.src = image;
    }

    draw(ctx: Ref) {
        ctx.value.drawImage(
            this.image,
            0,
            0,
            this.image.width,
            this.image.height,
            this.x,
            this.y,
            this.image.width * this.scale,
            this.image.height * this.scale
        );
    }
}


export class ShapeBuild extends Shape {

    constructor(image: string, x: number, y: number, speed: number, isPlayer: boolean, canvas: HTMLCanvasElement, scale: number) {
        super(image, x, y, speed, isPlayer, canvas, scale);
        this.image = new Image();
        // eslint-disable-next-line
        let obj = this;
        this.image.addEventListener("load", function () {
            obj.loaded = true;
            if (obj.x + obj.image.width * obj.scale > obj.canvas.width) {
                obj.x = obj.canvas.width - obj.image.width * obj.scale - 50;
            }
            if (obj.y + obj.image.height * obj.scale > obj.canvas.height) {
                obj.y = obj.canvas.height - obj.image.height * obj.scale - 50;
            }
        });
        this.image.addEventListener("error", function () { alert('That image was not found.'); });
        this.image.src = image;
    }
}


export class BuilderShape {
    resourceShape: Array<any>
    resourceExample: any
    canvas: Ref<HTMLCanvasElement>
    speed: number
    scale: number
    scaleExample: number
    lenResourcesStep: number
    currentShapeStep: number
    getShapeG: Generator<IShape>
    shapePlayer: IShape
    shapeExample: IShape
    shapeBuilt: Array<IShape>
    isBuildingCompleted: boolean
    shapePosXY: Array<number>
    playerPosArray: Array<{x: number, y: number}>
    getPlayerPosXY: Generator<{x: number, y: number}>

    constructor(resourceShape: Array<any>,
                resourceExample: any,
                canvas: Ref<HTMLCanvasElement>,
                speed: number,
                scale: number,
                scaleExample: number) {
        this.resourceShape = resourceShape;
        this.resourceExample = resourceExample;
        this.canvas = canvas;
        this.speed = speed;
        this.scale = scale;
        this.scaleExample = scaleExample;
        this.lenResourcesStep = resourceShape.length;
        this.currentShapeStep = 0;
        this.playerPosArray = [
            {'x': 200, 'y': 200},
            {'x': this.canvas.value.width - 200, 'y': this.canvas.value.height - 200},
            {'x': 200, 'y': this.canvas.value.height - 200},
        ];
        this.getPlayerPosXY = this.generatorPlayerPosXY();
        this.getShapeG = this.generatorShape();
        this.shapePlayer = this.getShapeG.next().value;
        this.shapeExample = new ShapeExample(this.resourceExample, 0, 50, this.speed, false, this.canvas.value, this.scaleExample);
        this.shapePlayer.dead = true;
        this.shapeBuilt = [this.shapePlayer,]
        this.shapePlayer = this.getShapeG.next().value;
        this.isBuildingCompleted = false;
        this.shapePosXY = [this.canvas.value.width / 2 - this.shapeBuilt[0].image.width / 2, this.canvas.value.height / 2 - this.shapeBuilt[0].image.height / 2];
        this.shapeBuilt[0].x = this.shapePosXY[0];
        this.shapeBuilt[0].y = this.shapePosXY[1];
    }

    * generatorPlayerPosXY() {
        while (true) {
            yield arrayRandElement(this.playerPosArray);
        }
    }

    * generatorShape() {
        while (true) {
            this.currentShapeStep = 0;
            while (this.currentShapeStep <= this.lenResourcesStep - 1) {
                const coor = this.getPlayerPosXY.next().value
                yield new ShapeBuild(this.resourceShape[this.currentShapeStep], coor.x, coor.y, this.speed, false, this.canvas.value, this.scale); //Player's object
                this.currentShapeStep += 1;
            }
            this.isBuildingCompleted = true;
        }
    }

    getShape() {
        if (this.shapePlayer.dead) {
            this.shapeBuilt.push(this.shapePlayer);
            this.shapeBuilt[this.shapeBuilt.length - 1].x = this.shapePosXY[0];
            this.shapeBuilt[this.shapeBuilt.length - 1].y = this.shapePosXY[1];
            this.shapePlayer = this.getShapeG.next().value;
        }
        return this.shapePlayer;
    }

    checkHit(val: Ref<boolean>) {
        if ((this.shapePlayer.x >= this.shapePosXY[0] - 10 && this.shapePlayer.x <= this.shapePosXY[0] + 10) && (this.shapePlayer.y >= this.shapePosXY[1] - 10 && this.shapePlayer.y <= this.shapePosXY[1] + 10)) {
            this.shapePlayer.x = this.shapePosXY[0];
            this.shapePlayer.y = this.shapePosXY[1];
            this.shapePlayer.dead = true;
            return !val.value
        } else {
            return val.value
        }
    }

    draw(ctx: Ref) {
        this.shapeExample.draw(ctx);
        this.shapeBuilt.forEach((shape) => {
            shape.draw(ctx);
        });
        this.shapePlayer.draw(ctx);
    }
}


class GetBuilderShape {
    shapeResourceArray: Array<any>
    shapeExampleArray: Array<any>
    canvas: Ref<HTMLCanvasElement>
    speed: number
    scale: number
    scaleExample: number
    getBuilderShape: Generator<IBuilderShape>
    curentShape: IBuilderShape

    constructor(shapeResourceArray: any[], shapeExampleArray: any[], canvas: Ref<HTMLCanvasElement>, speed: number, scale: number, scaleExample: number) {
        this.shapeResourceArray = shapeResourceArray;
        this.shapeExampleArray = shapeExampleArray;
        if (shapeResourceArray.length !== shapeExampleArray.length) {
            throw new ResourceLenError('Resource shape and example lengths are not equal')
        }
        this.canvas = canvas;
        this.speed = speed;
        this.scale = scale;
        this.scaleExample = scaleExample;
        this.getBuilderShape = this.generatorBuilderShape();
        this.curentShape = this.getBuilderShape.next().value;
    }

    * generatorBuilderShape() {
        while (true) {
            let currentStep = 0;
            const lenResource = this.shapeResourceArray.length;
            while (currentStep <= lenResource - 1) {
                yield new BuilderShape(this.shapeResourceArray[currentStep], this.shapeExampleArray[currentStep], this.canvas, this.speed, this.scale, this.scaleExample);
                currentStep += 1;
            }
        }
    }

    getBuilder() {
        if (this.curentShape.isBuildingCompleted) {
            this.curentShape = this.getBuilderShape.next().value;
            return this.curentShape
        } else {
            return this.curentShape
        }
    }
}


export function useGetBuilderShape(canvas: Ref<HTMLCanvasElement>, speed: number, scale: number, scaleExample: number) {
    const resorceShape = [
        [
            resource.homeImg1,
            resource.homeImg2,
            resource.homeImg3,
            resource.homeImg4,
            resource.homeImg5,
            resource.homeImg6,
            resource.homeImg7,
            resource.homeImg8,
        ],
        [
            resource.plainImg1,
            resource.plainImg2,
            resource.plainImg3,
            resource.plainImg4,
            resource.plainImg5,
            resource.plainImg6,
            resource.plainImg7,
            resource.plainImg8,
            resource.plainImg9,
            resource.plainImg10,
        ]
    ];

    const exampleShape = [
        resource.homeImg,
        resource.plainImg
    ]

    return new GetBuilderShape(resorceShape, exampleShape, canvas, speed, scale, scaleExample)
}